import { mascaraMoeda, maskCurrency } from '../../../../utils/utils'
import { useCompany } from "../../../../hooks/useCompany"
import { Toast } from "../../../../utils/Toast"
import { api } from "../../../../api/api"
import { ModalImageLogo } from '../../../../components/Modals/ImageCrop'
import { useState } from 'react'
import CompanyService from '../../../../services/company';
import { DEFAULT_IMAGE } from '../../../../utils/constantes'
import { QRCodeCanvas } from 'qrcode.react';
import { DefineStyle } from './components/DefineStyle'

export function Configuration() {
  const { configurations, updateConfiguration, company } = useCompany()

  const [whenCrop, setWhenCrop] = useState('banner')

  async function save() {
    await api.post('/configurations', configurations)
    Toast.success()
  }

  function getUrlCardapio() {
    if (company.domain) {
      return `https://${company.domain}/${company.link}`
    } else {
      return `${process.env.REACT_APP_CARDAPIO}/${company.link}`
    }
  }

  return (
    <div className='mt-3'>

      <ModalImageLogo
        toggleModal={() => new window.bootstrap.Modal(document.getElementById('modalCropImageLogo'), {}).hide()}
        uploadImageBanner={whenCrop === 'banner' ? CompanyService.uploadImageBanner : CompanyService.uploadLogo}
        title={whenCrop === 'banner' ? 'Cortar banner' : 'Cortar logo'}
      />
      <div className="banner p-2">
        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', gap: 20 }}>
          <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', gap: 10 }}>
            <h2 className="text-center">Banner</h2>
            <img
              loading="lazy"
              style={{ width: window.screen.width < 1600 ? 350 : 500, height: 250, objectFit: 'contain' }}
              src={company.banner ?? DEFAULT_IMAGE}
              className="img-fluid col-sm-6 mb-4 rounded border" alt=""
            />
            <button className="btn btn-sm btn btn-sm" style={{ border: '1px solid #666' }} onClick={() => {
              setWhenCrop('banner')
              new window.bootstrap.Modal(document.getElementById('modalCropImageLogo'), {}).toggle()
            }}>Enviar imagem</button>
          </div>
          <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center', gap: 10 }}>
            <h2 className="text-center">Logo</h2>
            <img
              loading="lazy"
              style={{ width: window.screen.width < 1600 ? 350 : 500, height: 250, objectFit: 'contain' }}
              src={company.logo ?? DEFAULT_IMAGE}
              className="border" alt=""
            />
            <button className="btn btn-sm btn btn-sm " style={{ border: '1px solid #666' }} onClick={() => {
              setWhenCrop('logo')
              new window.bootstrap.Modal(document.getElementById('modalCropImageLogo'), {}).toggle()
            }}>Enviar imagem</button>
          </div>
        </div>

        <DefineStyle />

      </div>
      <hr />
      <h5 className="text-gray card-title" style={{ fontSize: '1.2rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Configurações do pedido
      </h5>
      <div className="row">
        <div className="col-sm-6 mt-2">
          <label htmlFor="">Preço minimo para entrega</label>
          <input
            className="form-control form-control-sm"
            onChange={(e) => updateConfiguration({ pricingMinDelivery: mascaraMoeda(e.target.value) })}
            value={maskCurrency(configurations.pricingMinDelivery)}
            type="text"
          />
        </div>
        <div className="col-sm-6 mt-2">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={configurations.activeRetirada}
              onChange={(e) => updateConfiguration({ activeRetirada: e.target.checked })}
              id="ActiveRetirada" />
            <label className="form-check-label" htmlFor="ActiveRetirada">
              Ativar retirada
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={configurations.activeRating}
              onChange={(e) => updateConfiguration({ activeRating: e.target.checked })}
              id="activeRating" />
            <label className="form-check-label" htmlFor="activeRating">
              Ativar Avaliação ao finalizar pedido
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={configurations.sound}
              onChange={(e) => updateConfiguration({ sound: e.target.checked })}
              id="activeSound" />
            <label className="form-check-label" htmlFor="activeSound">
              Ativar som ao chegar um novo pedido
            </label>
          </div>
        </div>
      </div>

      <hr />

      <>
        <h5 className="text-gray card-title mt-3" style={{ fontSize: '1.2rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Configuração de impressão
        </h5>
        <div className="row">
          <div className='col-sm-12 mt-2'>
            <button className='btn btn-sm btn-primary' onClick={async () => window.open('https://pedidos-images.s3.amazonaws.com/BMS+Pedidos+-+Configura%C3%A7%C3%A3o+Impressora.exe', '_blank')}>Baixar configurador de impressão</button>
          </div>

          <div className="col-sm-12 mt-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.autoPrintMesa}
                onChange={(e) => updateConfiguration({ autoPrintMesa: e.target.checked })}
                id="autoPrintMesa" />
              <label className="form-check-label" htmlFor="autoPrintMesa">
                Impressão pedido de mesa automático?
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.autoPrintIfood}
                onChange={(e) => updateConfiguration({ autoPrintIfood: e.target.checked })}
                id="autoPrintIfood" />
              <label className="form-check-label" htmlFor="autoPrintIfood">
                Impressão pedido do ifood?
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.autoPrintDelivery}
                onChange={(e) => updateConfiguration({ autoPrintDelivery: e.target.checked })}
                id="autoPrintDelivery" />
              <label className="form-check-label" htmlFor="autoPrintDelivery">
                Impressão pedido do delivery automático?
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.autoPrintBalcao}
                onChange={(e) => updateConfiguration({ autoPrintBalcao: e.target.checked })}
                id="autoPrintBalcao" />
              <label className="form-check-label" htmlFor="autoPrintBalcao">
                Impressão pedido do balcão automático?
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.autoPrintOnAddProduct}
                onChange={(e) => updateConfiguration({ autoPrintOnAddProduct: e.target.checked })}
                id="autoPrintOnAddProduct" />
              <label className="form-check-label" htmlFor="autoPrintOnAddProduct">
                Impressão dos produtos adicionados ao alterar pedido?
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.autoPrintOrder}
                onChange={(e) => updateConfiguration({ autoPrintOrder: e.target.checked })}
                id="autoPrintOrder" />
              <label className="form-check-label" htmlFor="autoPrintOrder">
                Impressão automática ao chegar novo pedido?
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.printCancelProductOrder}
                onChange={(e) => updateConfiguration({ printCancelProductOrder: e.target.checked })}
                id="printCancelProductOrder" />
              <label className="form-check-label" htmlFor="printCancelProductOrder">
                Impressão ao cancelar/remover produto do pedido?
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.printCancelOrder}
                onChange={(e) => updateConfiguration({ printCancelOrder: e.target.checked })}
                id="printCancelOrder" />
              <label className="form-check-label" htmlFor="printCancelOrder">
                Impressão ao Cancelar pedido?
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={configurations.automaticProductAcceptance}
                onChange={(e) => updateConfiguration({ automaticProductAcceptance: e.target.checked })}
                id="automaticProductAcceptance" />
              <label className="form-check-label" htmlFor="automaticProductAcceptance">
                Aceite automático de produto?
              </label>
            </div>
          </div>


          <div className='row col-sm-12'>
            <p className='text-gray mt-2 mb-2' style={{ fontSize: '1.2rem', }}>Configurar impressora padrão (Pedidos)</p>
            <div className='col-sm-4'>
              <input
                value={configurations.defaultNamePrint}
                onChange={(e) => updateConfiguration({ defaultNamePrint: e.target.value })}
                placeholder='Nome da impressora padrão'
                type="text" className='form-control form-control-sm'
              />
            </div>
            <div className='col-sm-4'>
              <input
                value={configurations.defaultCodePrint}
                onChange={(e) => updateConfiguration({ defaultCodePrint: e.target.value })}
                placeholder='Código do aplicativo de impressão padrão'
                type="text" className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className="col-sm-12 mt-2">
            <label htmlFor="">Mensagem</label>
            <input
              className="form-control form-control-sm"
              onChange={(e) => updateConfiguration({ messagePrint: e.target.value })}
              value={configurations.messagePrint}
              type="text"
              maxLength={200}
            />
            <div className="form-text">Mensagem para aparecer na hora da impressão</div>
          </div>

        </div>
        <div className="divider mt-3 mb-3"></div>
      </>


      <h5 className="text-gray card-title mt-3" style={{ fontSize: '1.2rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        QRCode Cardápio
      </h5>
      <p>Ao escanear o qrcode seu cliente terá acesso ao se cardápio.</p>
      <br />
      <div>
        <QRCodeCanvas id='canva-qrcode' size={256} value={getUrlCardapio()} />
      </div>
      <button onClick={() => {
        var link = document.createElement('a');
        link.download = 'qrcode.png';
        link.href = document.getElementById('canva-qrcode').toDataURL()
        link.click();
      }} className='btn mt-2' style={{ border: '1px solid #666' }}>Baixar QRCode</button>
      <div className="divider mt-3 mb-3"></div>

      <div className="text-start">
        <button className="btn btn btn-dark-blue" onClick={() => save()}>Salvar informações</button>
      </div>
    </div>
  )
}