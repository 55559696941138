import { useEffect, useState } from "react"
import { useSWRConfig } from "swr"

import { mascaraMoeda, maskCurrency } from "../../../utils/utils"
import { api } from "../../../api/api"
import { Toast } from "../../../utils/Toast"
import { useDispatch, useSelector } from "react-redux"
import { ADD_NEW_SAFE, INITIAL_STATE, SET_SAFE } from "../../../stores/reducers/SafeReducers"
import { DateTime } from "luxon"

export function ModalSafe() {
  const dispatch = useDispatch()
  const safe = useSelector(state => state.safe.safe)
  const { mutate } = useSWRConfig()
  const [state, setState] = useState({
    id: null,
    type: "saida",
    typePayment: "money",
    pricing: 0,
    orderId: null,
    description: "",
    date: DateTime.local().toSQLDate()
  })

  async function create() {
    await api.post('/safe', state)
    Toast.success()
    dispatch(ADD_NEW_SAFE(state))
    setState(INITIAL_STATE.safe)

    await mutate('/safe/load/data')
  }

  useEffect(() => {
    if (safe) {
      setState(safe)
    }
  }, [safe])

  useEffect(() => {
    if (state && state.type === 'sangria' && state.typePayment !== 'money') {
      Toast.warning('Sangria só pode ser efetuada, com o tipo de pagamento (Dinheiro)')
      setState({
        ...state,
        typePayment: 'money'
      })
    }
  }, [state])

  return (
    <>
      <div className="modal fade" id="modalSafe" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className={`modal-dialog modal-lg`}>
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title" style={{ display: 'flex', alignItems: "flex-end" }}>
                <p style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>Caixa</p>
              </div>
              <button
                type="button"
                id='modalSafeClose'
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  dispatch(SET_SAFE({
                    id: null,
                    type: "saida",
                    pricing: 0,
                    orderId: null,
                    description: "",
                    date: DateTime.local().toSQLDate()
                  }))
                }}
              >
              </button>
            </div>
            <div className="modal-body" style={{ maxHeight: '80vh', overflow: 'auto' }}>
              <div className="row">
                <div className="col-sm-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Tipo</label>
                  <select disabled={!!state.id} value={state.type} onChange={(e) => setState({ ...state, type: e.target.value })} className="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option value="entrada">Entrada</option>
                    <option value="saida">Saída</option>
                    <option value="sangria">Sangria</option>
                  </select>
                </div>
                <div className="col-sm-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Tipo</label>
                  <select disabled={!!state.id} value={state.typePayment} onChange={(e) => setState({ ...state, typePayment: e.target.value || 'money' })} className="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option value="money">Dinheiro</option>
                    <option value="card">Cartão</option>
                    <option value="pix">PIX</option>
                    <option value="ifood">Ifood</option>
                  </select>
                </div>
                <div className="col-sm-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Data</label>
                  <input disabled={!!state.id} type="date" value={state.date} onChange={(e) => setState({ ...state, date: e.target.value })} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Valor</label>
                  <input disabled={!!state.id} type="text" onChange={(e) => setState({ ...state, pricing: mascaraMoeda(e.target.value) })} value={maskCurrency(state.pricing)} className="form-control form-control-sm" />
                </div>
                <div className="col-sm-12">
                  <label htmlFor="exampleFormControlTextarea1" className="form-label">Descrição</label>
                  <textarea
                    disabled={!!state.id}
                    className="form-control"
                    value={state.description}
                    onChange={(e) => setState({ ...state, description: e.target.value })}
                    id="exampleFormControlTextarea1" rows="3">
                  </textarea>
                </div>
                {!state.id && (
                  <div className="col-sm-12">
                    <button className="btn btn-dark-blue btn-sm mt-3" onClick={() => create()}>Salvar informação</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div >
    </>

  )
}