import { FaMoneyBillWave, FaShoppingCart, FaUserAlt, FaWhatsappSquare } from "react-icons/fa";
import { useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCheckCircle } from "react-icons/ai";
import 'react-datalist-input/dist/styles.css';
import ReactInputMask from "react-input-mask"
import { useSWRConfig } from "swr"


import { SELECT_ORDER, UPDATE_ORDER, UPDATE_SELECT_ORDER } from "../../../../stores/reducers/OrderReducers";
import { mascaraMoeda, maskCurrency } from "../../../../utils/utils";
import { OrderContext } from "../../../../context/OrderContext";
import { useMotoboy } from "../../../../hooks/useMotoboy";
import { Toast } from "../../../../utils/Toast";
import { api } from "../../../../api/api";
import { GlobalContext } from "../../../../context/GlobalContext";
import { TbBrandWhatsapp, TbTruckDelivery } from 'react-icons/tb'
import { BsPlusSquare } from "react-icons/bs";
import { MdDeliveryDining } from "react-icons/md";
import { useCompany } from "../../../../hooks/useCompany";
import { ListProducts } from "./ListProducts";
import { IoMdCloseCircle } from "react-icons/io";
import { Payments } from "./Payments";
import { sendPrint, sendPrintNota } from "../../../../reporting/print";
import { AddProduct } from "./AddProduct";

let timeout = null

export function BodyModal () {
  const { isAccess, user } = useContext(GlobalContext)
  const order = useSelector(state => state.order.orderSelect)
  const { motoboys } = useMotoboy()
  const dispatch = useDispatch()
  const { mutate } = useSWRConfig()
  const { configurations } = useCompany()

  const { calculatePricingTotal, calculateTotal, updated: updatedContext, selectOrder } = useContext(OrderContext)
  const [visibleAddProduct, setVisibleAddProduct] = useState(false)
  const inputRef = useRef()

  async function onChange (key, valueInput) {
    if (!isAccess('pedidos', 'update')) {
      return Toast.warning('Você não tem permissão para atualizar pedido')
    }

    if (key === 'deliveryDistrict') {
      valueInput = {
        ...order.deliveryDistrict,
        district: valueInput,
      }
    }

    if (key === 'motoboy' && valueInput !== "Selecione") {
      const dt = motoboys.find(e => e.id === valueInput)
      valueInput = {
        ...dt,
      }
    }

    if (key === 'productsOrders') {
      const product = order.productsOrders.find((e) => e.id === valueInput.id)
      Reflect.deleteProperty(valueInput, 'id')
      const aux = {
        ...product,
        ...valueInput
      }

      valueInput = order.productsOrders.map(e => {
        if (e.id !== product.id) {
          return e
        }

        return aux
      })

      const newOrder = {
        ...order,
        [key]: valueInput,
      }

      const totalUpdated = calculateTotal(newOrder)

      const payload = {
        ...newOrder,
        total: totalUpdated
      }

      if (order.typeOrder === 'mesa') {
        dispatch(UPDATE_SELECT_ORDER(payload))
      }

      if (order.typeOrder !== 'mesa') {
        dispatch(UPDATE_ORDER(payload))
      }

      await update(false, true, payload)

      return
    }

    const payload = {
      ...order,
      [key]: valueInput
    }

    if (order.typeOrder === 'mesa') {
      dispatch(UPDATE_SELECT_ORDER(payload))
    }

    if (order.typeOrder !== 'mesa') {
      dispatch(UPDATE_ORDER(payload))
    }

    clearTimeout(timeout)

    const newTimer = setTimeout(async () => {
      await update(false, true, payload)
    }, 800)

    timeout = newTimer
  }

  async function removeProduct (id) {
    const confirmation = await Toast.confirm()
    if (!confirmation) {
      return
    }

    const newOrder = {
      ...order,
      productsOrders: order.productsOrders.filter((e) => e.id !== id),
    }

    const totalUpdated = calculateTotal(newOrder)

    const payload = {
      ...newOrder,
      total: totalUpdated
    }

    await sendPrint('cancel-product-order', { product: order.productsOrders.find((e) => e.id === id) }, order.id)

    dispatch(UPDATE_ORDER(payload))

    await update(false, true, payload)
  }

  async function update (closeModal = true, toast = true, payload = null) {
    const dataOrder = payload ?? { ...order }
    const dataProductsOrders = [...(payload?.productsOrders ?? order?.productsOrders) ?? []]
    Reflect.deleteProperty(dataOrder, 'productsOrders')
    Reflect.deleteProperty(dataOrder, 'createdAt')
    Reflect.deleteProperty(dataOrder, 'updatedAt')
    Reflect.deleteProperty(dataOrder, 'response')
    Reflect.deleteProperty(dataOrder, 'numOrder')

    try {
      const response = await api.put('/orders/' + dataOrder.id, {
        order: dataOrder,
        productsOrders: dataProductsOrders
      })

      if (toast) {
        Toast.success()
      }

      if (closeModal) {
        document.getElementById('modalOrderClose').click()
      }

      if (order.typeOrder === 'mesa') {
        dispatch(UPDATE_SELECT_ORDER(response.data))
      }

      if (order.typeOrder !== 'mesa') {
        dispatch(UPDATE_ORDER(response.data))
      }

      return true
    } catch (err) {
      const paylaodOrder = await selectOrder(payload.id)
      dispatch(SELECT_ORDER(paylaodOrder))
      return false
    }
  }

  async function updateOrderFrenteCaixa () {
    const confirmation = await Toast.confirm('Deseja fechar e lançar no caixa pedido?', 'Não', 'Sim')

    if (!confirmation) {
      return
    }

    const body = {
      id: order.id,
      status: 'finalizado'
    }

    dispatch(UPDATE_ORDER(body))

    Promise.all([
      await api.put('/orders/' + order.id, { status: 'finalizado' }),
      await mutate('/safe/load/data')
    ])

    document.getElementById('modalOrderClose').click()
  }

  function openConversationWpp (phone = '') {
    if (!phone) {
      return
    }

    phone = phone.replace('(', '').replace(')', '').replace(/ /ig, '')

    window.open(`https://api.whatsapp.com/send?phone=55${phone}`, '_blank')

  }

  async function sendCupom (idOrder) {
    try {
      if (user.plan !== 'complet') {
        return Toast.warning('Apenas plano Completo pode emitir cupom fiscal.')
      }
      Toast.success("Estamos emitindo o cupom.")

      const { data } = await api.post('/nfce', { idOrder })

      // if (!configurations.defaultNamePrint || !configurations.defaultCodePrint) {
      //   window.open(data.dataWebMania.danfe)
      // } else {
      //   sendPrintNota(data.dataWebMania.danfe, configurations.defaultCodePrint, configurations.defaultNamePrint)
      // }

      dispatch(SELECT_ORDER({
        ...order,
        response: data
      }))

      Toast.success("Cupom emitido com sucesso.")

      document.getElementById('modalOrderClose').click()
    } catch (error) { }
  }

  async function closeOrCancelOrder (cancel = false) {
    const confirmation = await Toast.confirm(`Deseja ${cancel ? 'Cancelar' : 'Finalizar'} o pedido?`, "Não", "Sim")

    if (confirmation) {

      if (cancel) {
        if (isAccess('pedidos', 'cancelar_pedido')) {
          await sendPrint('cancel-order', {}, order.id)
          await updatedContext(order.id, 'status', 'cancelado', false, false)
        } else {
          return Toast.warning('Você não tem permissão para cancelar pedido')
        }
      } else {
        if (isAccess('pedidos', 'update')) {
          await updatedContext(order.id, 'status', 'finalizado', false, false)
        } else {
          return Toast.warning('Você não tem permissão para atualizar pedido')
        }
      }

      Toast.success(`Pedido ${cancel ? 'cancelado' : 'finalizado'} com sucesso`)

      await mutate('list-tables')
      return
    }
  }

  function changePhonesRemoveCaracter (value = '') {
    return value
      .replace(/\(/ig, '')
      .replace(/\)/ig, '')
      .replace(/_/ig, '')
      .replace(/-/ig, '')
      .replace(/ /ig, '')
  }

  const { totalWithDiscount, discount, totalProducts } = calculatePricingTotal(order, true)
  const frete = maskCurrency(order?.deliveryDistrict?.pricing ?? 0)
  const freeFrete = Boolean(order?.deliveryDistrict?.free ?? 0)
  const moneyChange = maskCurrency(order?.moneyChange ?? 0)

  if (!order) {
    return <>Carregando </>
  }


  return (
    <>
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
            Pedido
          </button>
          <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
            Clientes
          </button>
          {isAccess() !== 'garcom' && (
            <button onClick={() => setTimeout(() => inputRef.current.focus(), 500)} className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
              Pagamentos
            </button>
          )}
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
          <div className="container mt-3">
            <div className={`${isAccess() === 'garcom' ? '' : 'modal-order-content'}`} style={{ width: '100%' }}>
              <div>
                <div className="mt-1">
                  {(order.typeOrder === 'delivery' || order.typeOrder === 'frente-de-caixa') && (
                    <>
                      <div style={{ display: 'flex', gap: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex' }}>
                          <FaUserAlt size={40} style={{ border: '1px solid #dcdcdc', marginRight: 10, padding: 4, borderRadius: 5 }} />
                          <div>
                            <p style={{ fontWeight: 600 }}>Cliente</p>
                            <p className="text-secondary">{order.name}</p>
                          </div>
                        </div>

                        <div>
                          <TbBrandWhatsapp onClick={() => openConversationWpp(order.phone)} className="pointer" size={40} color="green" style={{ border: '1px solid #dcdcdc', margin: 4, padding: 4, borderRadius: 5 }} />
                        </div>
                      </div>

                      <div className="mt-2" style={{ display: 'flex', gap: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex' }}>
                          <TbTruckDelivery size={40} style={{ border: '1px solid #dcdcdc', marginRight: 10, padding: 4, borderRadius: 5 }} />
                          <div>
                            <p style={{ fontWeight: 600 }}>Entrega</p>
                            {order.typeOrder === 'delivery' && (
                              <>
                                {order.address && (
                                  <p className="text-secondary">{order.address}, {order.number}, {order.complement} - {order?.deliveryDistrict?.district}</p>
                                )}
                                {!order.address && (
                                  <p className="text-secondary">Endereço não informado</p>
                                )}
                              </>
                            )}

                            {order.typeOrder !== 'delivery' && (
                              <>
                                <p className="text-secondary">Não definido</p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mt-2" style={{ width: '100%', display: 'flex', gap: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                          <MdDeliveryDining size={40} style={{ border: '1px solid #dcdcdc', marginRight: 10, padding: 4, borderRadius: 5 }} />
                          <div style={{ width: '100%' }}>
                            <p style={{ fontWeight: 600 }}>Entregador</p>
                            {order.typeOrder === 'delivery' && (
                              <>
                                <select value={order?.motoboy?.id ?? "Selecione"} className="w-100 form-control form-control-sm" onChange={(e) => onChange('motoboy', e.target.value)} >
                                  <option value={null}>Selecione</option>
                                  {motoboys.map((motoboy) => {
                                    return <option value={motoboy.id} key={motoboy.id}>{motoboy.name}</option>
                                  })}
                                </select>
                              </>
                            )}

                            {order.typeOrder !== 'delivery' && (
                              <>
                                <p style={{ color: '#666' }}>Não informado</p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {isAccess() !== 'garcom' && (
                  <div className="mt-2">
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                      <FaMoneyBillWave size={40} style={{ border: '1px solid #dcdcdc', marginRight: 10, padding: 4, borderRadius: 5 }} />
                      <p>Informações de Pagamento</p>
                    </div>
                    <div className="mt-2" style={{ borderRadius: 5 }}>
                      {(order.typeOrder === 'delivery') && (
                        <div className=" d-flex align-items-center text-dark justify-content-between p-1">
                          <p>Frete</p>
                          {freeFrete && (
                            <p style={{ textDecoration: 'line-through' }}>{frete}</p>
                          )}

                          {!freeFrete && (
                            <p>
                              {frete}
                            </p>
                          )}
                        </div>
                      )}

                      {(freeFrete) && (
                        <div className="d-flex align-items-center text-dark justify-content-between p-1">
                          <p>Desconto de Frete</p>
                          {freeFrete && (
                            <p>{frete}</p>
                          )}
                        </div>
                      )}
                      <div style={{ display: order.cupomType ? 'block' : 'none' }} className="d-flex align-items-center text-dark justify-content-between p-1">
                        <p>Cupom de Desconto</p>
                        <p>{(order.cupomType === 'porcentagem' ? `${order.cupomDiscount}%` : maskCurrency(discount ?? 0))} </p>
                      </div>
                      <div className="d-flex align-items-center text-dark justify-content-between p-1">
                        <p>Troco para</p>
                        <p>{moneyChange} (<span style={{ fontWeight: 900 }}>Troco: {moneyChange === maskCurrency(0) ? maskCurrency(0) : maskCurrency(Number((order?.moneyChange ?? 0) - (totalWithDiscount ?? 0))?.toPrecision(3)) ?? 0}</span>)</p>
                      </div>
                      <div className="d-flex align-items-center text-dark justify-content-between p-1">
                        <p>Total pedido</p>
                        <p style={{ fontWeight: 'bold' }}>{maskCurrency(totalWithDiscount)}</p>
                      </div>
                      <div className="col-sm-12">
                        <div className="col-sm-12 row">
                          <div className="col-sm-4">
                            <label htmlFor="">Desconto</label>
                            <input className="form-control form-control-sm" type="text" value={maskCurrency(order.discount)} onChange={(e) => onChange('discount', mascaraMoeda(e.target.value))} />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="">Acréscimo</label>
                            <input className="form-control form-control-sm" type="text" value={maskCurrency(order.extra)} onChange={(e) => onChange('extra', mascaraMoeda(e.target.value))} />
                          </div>
                          <div className="col-sm-4">
                            <label htmlFor="">Troco</label>
                            <input className="form-control form-control-sm" type="text" value={maskCurrency(order?.moneyChange)} onChange={(e) => onChange('moneyChange', mascaraMoeda(e.target.value))} />
                          </div>
                        </div>

                        <div className="mt-2">
                          <label htmlFor="">Forma de pagamento</label>
                          <select className="form-select form-select-sm" defaultValue={order.paymentMethod} onChange={(e) => onChange('paymentMethod', e.target.value)} aria-label=".form-select-sm example">
                            <option value="money">Dinheiro</option>
                            <option value="card">Cartão</option>
                            <option value="pix">Pix</option>
                          </select>
                        </div>

                        <div className="mt-2">
                          <label htmlFor="exampleFormControlTextarea1" className="form-label">Descrição</label>
                          <textarea className="form-control"
                            id="exampleFormControlTextarea1" rows="1"
                            onChange={(e) => onChange('description', e.target.value)}
                            value={order.description}
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <br />

                <div style={{ width: '100%' }}>
                  {isAccess() !== 'garcom' && (
                    <>
                      {order.status === 'aguardando' && (
                        <div className="col-sm-4 mt-3">
                          <button
                            className="btn btn-sm text-white fw-bold w-100 btn-success"
                            style={{
                              display: order.status === 'aguardando' ? 'block' : 'none'
                            }}
                            onClick={async () => {
                              await updatedContext(order.id, 'status', 'aprovado', false)
                              document.getElementById('modalOrderClose').click()
                            }}
                          >
                            Aprovar pedido <AiFillCheckCircle size={20} />
                          </button>
                        </div>
                      )}

                      <div className="d-flex align-items-center justify-content-arround mt-2 gap-2">
                        {(order.typeOrder === 'mesa' && !['finalizado', 'cancelado'].includes(order.status) && isAccess('pedidos', 'cancelar_pedido')) && (
                          <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                            <button style={{}} className="btn btn-sm btn-outline-danger" onClick={() => closeOrCancelOrder(true)}>Cancelar pedido</button>
                            <button style={{}} className="btn btn-sm btn-outline-secondary" onClick={() => closeOrCancelOrder(false)}>Finalizar pedido</button>
                          </div>
                        )}

                        <div>
                          <button style={{
                            display: (order.typeOrder === 'frente-de-caixa' && order.status !== 'finalizado' && order.status !== 'cancelado') ? 'block' : 'none'
                          }} className="btn btn-sm btn-outline-primary" onClick={() => updateOrderFrenteCaixa()}>Salvar pedido e finalizar</button>
                        </div>


                        {(order.typeOrder !== 'mesa' && !['finalizado', 'cancelado'].includes(order.status) && isAccess('pedidos', 'cancelar_pedido')) && (
                          <div>
                            <button className="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#modalRefusedOrder">Cancelar pedido</button>
                          </div>
                        )}

                        {isAccess('fiscal', 'emissao') && order.status !== 'cancelado' && (
                          <div>
                            {((!order.response)) && (
                              <>
                                <button className="btn btn-sm btn-outline-dark" onClick={() => sendCupom(order.id)} >Emitir Cupom</button>
                              </>
                            )}

                            {(order.response) && (
                              <>
                                <button className="btn btn-sm btn-dark" onClick={() => sendPrintNota(order.response.danfe, configurations.defaultCodePrint, configurations.defaultNamePrint)}>Imprimir Cupom</button>
                              </>
                            )}
                          </div>
                        )}

                        <div>
                          <button className="btn btn-sm btn-outline-primary" onClick={async () => sendPrint('order', {}, order?.id)}>Imprimir pedido</button>
                        </div>
                      </div>
                    </>
                  )}
                  {isAccess() === 'garcom' && (
                    <button style={{}} className="mt-3 btn btn-sm btn-outline-primary" onClick={async () => sendPrint('order', {}, order?.id)}>Imprimir pedido</button>
                  )}
                </div>
              </div>
              <div className="element-scrol-produtcts-add" style={{ width: '100%', paddingRight: 10 }}>
                <div style={{ display: 'flex', gap: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <FaShoppingCart size={40} style={{ border: '1px solid #dcdcdc', marginRight: 10, padding: 4, borderRadius: 5 }} />
                    <p style={{ fontWeight: 600 }}>Produtos</p>
                  </div>

                  {isAccess('pedidos', 'update') && (
                    <div>
                      {visibleAddProduct && (
                        <IoMdCloseCircle onClick={() => {
                          setVisibleAddProduct(state => !state)

                        }} className="pointer" size={40} style={{ margin: 4, padding: 4, borderRadius: 5 }} />
                      )}

                      {!visibleAddProduct && (
                        <BsPlusSquare onClick={() => {
                          setVisibleAddProduct(state => !state)
                        }} className="pointer" size={40} style={{ margin: 4, padding: 4, borderRadius: 5 }} />
                      )}
                    </div>
                  )}
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                  {order?.productsOrders?.length > 0 && !visibleAddProduct &&
                    <button className="btn btn-sm btn-outline-dark mb-2 mt-2" onClick={async () => {
                      await sendPrint('product-order', { dataProducts: order.productsOrders }, order.id)
                    }}>Imprimir todos produtos</button>
                  }
                </div>

                {visibleAddProduct && (
                  <div style={{}}>
                    <AddProduct order={order} update={update} setVisibleAddProduct={setVisibleAddProduct} />
                  </div>
                )}

                <br />
                {(!visibleAddProduct) && (
                  <>
                    <div style={{ border: '1px solid #dcdcdc', padding: 10, borderRadius: 5, maxHeight: 350, overflow: 'auto' }}>
                      <ListProducts products={order.productsOrders} removeProduct={removeProduct} removeDisabled={!isAccess('pedidos', 'update')} />
                    </div>
                    <div style={{ marginTop: 10, backgroundColor: '#dcdcdc', padding: 10, borderRadius: 5, fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <p>Total produtos:</p>
                      <p>{maskCurrency(totalProducts)}</p>
                    </div>
                  </>
                )}
              </div>
            </div>

          </div>
        </div>
        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <div className="container">
            <div className="mt-3 mb-3" style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <FaUserAlt size={40} className="border rounded p-1" />
              <p className="p-0 m-0 fw-bold">Informações do Cliente</p>
            </div>
            <div className="divider mt-1"></div>
            <div className="row mt-2">
              <div className="col-sm-3">
                <label htmlFor="">Nome</label>
                <input
                  maxLength={100}
                  value={order.name}
                  onChange={(e) => onChange('name', e.target.value)}
                  className="form-control form-control-sm" type="text"
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="">Cpf</label>
                <ReactInputMask
                  mask="999.999.999-99"
                  className="form-control form-control-sm"
                  value={order?.cpf ?? ''}
                  onChange={(e) => onChange('cpf', changePhonesRemoveCaracter(e.target.value))}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="">Telefone <FaWhatsappSquare size={15} className="pointer" color="green" onClick={() => openConversationWpp(order.phone)} /> </label>
                <ReactInputMask
                  mask="(99) 99999-9999"
                  className="form-control form-control-sm"
                  value={order?.phone ?? ''}
                  onChange={(e) => onChange('phone', changePhonesRemoveCaracter(e.target.value))}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="">Endereço</label>
                <input
                  maxLength={100}
                  className="form-control form-control-sm"
                  type="text"
                  value={order.address}
                  onChange={(e) => onChange('address', e.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="">Nº</label>
                <input
                  maxLength={40}
                  className="form-control form-control-sm"
                  type="text"
                  value={order.number}
                  onChange={(e) => onChange('number', e.target.value)}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="">Complemento</label>
                <input
                  maxLength={50}
                  className="form-control form-control-sm"
                  type="text"
                  value={order.complement}
                  onChange={(e) => onChange('complement', e.target.value)}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="">Bairro</label>
                <input
                  maxLength={100}
                  className="form-control form-control-sm"
                  type="text"
                  value={order.deliveryDistrict?.district}
                  onChange={(e) => onChange('deliveryDistrict', e.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="">CEP</label>
                <input
                  maxLength={10}
                  className="form-control form-control-sm"
                  type="text"
                  value={order.cep}
                  onChange={(e) => onChange('cep', e.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="">Referência</label>
                <input
                  maxLength={50}
                  className="form-control form-control-sm"
                  type="text"
                  value={order.referencePoint}
                  onChange={(e) => onChange('referencePoint', e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {isAccess() !== 'garcom' && (
          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
            <Payments total={totalWithDiscount} order={order} closeOrCancelOrder={closeOrCancelOrder} inputRef={inputRef} />
          </div>
        )}

      </div >

    </>
  )
}