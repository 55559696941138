import { useEffect, useRef, useState } from "react"
import { DateTime } from "luxon"
import useSWR, { useSWRConfig } from 'swr'

import { OrdersByCustomers } from './components/OrdersByCustomers'
import { FaSearch, FaTrashAlt } from 'react-icons/fa'

import { NavBar } from "../../components/NavBar"
import { Toast } from "../../utils/Toast"
import { api } from "../../api/api"
import { BiGroup } from "react-icons/bi"
import _ from "lodash"


export function CustomerPage () {
  const refSearch = useRef()
  const [search, setSearch] = useState('')

  const [customerSelect, setCustomerSelect] = useState(null)

  const { mutate } = useSWRConfig()
  const { data, isLoading } = useSWR(['/customers', search], async (args) => {
    const response = await api.get(`/customers?qs=${args[1]}`)
    return _.orderBy(response?.data ?? [], 'name', 'asc')
  })

  useEffect(() => {
    if (customerSelect !== null)
      new window.bootstrap.Modal(document.getElementById('modalOrdersByCustomer'), {}).toggle()
  }, [customerSelect])

  async function destroy (id) {
    const confirmation = await Toast.confirm()
    if (confirmation) {
      await api.delete('/customers/' + id)
      await mutate('/customers')
    }
  }

  async function reportInactiveClients () {
    const value = await Toast.getValue('Digite a quantidade de dias', null, null, null, 'number')

    if (value === undefined) {
      return
    }

    if (value === '') {
      return Toast.warning('Deve ser digitado um valor em dias')
    }

    const date = DateTime.local().minus({ day: value }).toSQLDate()
    const response = await api.get(`/customers/list-inactive?date=${date}`)

    if (response.data.length === 0) {
      return Toast.warning('Não tem nenhum cliente nessa data')
    }

    const responseData = _.orderBy(response.data.map(e => {
      return {
        'Nome': e.name,
        'Telefone': e.phone,
        'Ultimo pedido': DateTime.fromISO(e.lastOrder).toFormat('dd/MM/yyyy'),
      }
    }), 'Ultimo pedido', 'asc')

    const tableHtml = `
      <html>
        <head>
          <title>Recuperação de clientes</title>
          <style>
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 100vh;
              margin: 0;
              font-family: Arial, sans-serif;
            }
            table {
              width: 800px;
              border-collapse: collapse;
              border-radius: 4px;
              overflow: hidden;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }
            table, th, td {
              border: 1px solid #ddd;
            }
            th, td {
              padding: 12px;
              text-align: left;
            }
            th {
              background-color: #4CAF50;
              color: white;
            }
            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <table>
            <thead>
              <tr>${Object.keys(responseData[0]).map(key => `<th>${key}</th>`).join('')}</tr>
            </thead>
            <tbody>
              ${responseData.map(row => `
                <tr>${Object.values(row).map(value => `<td>${value}</td>`).join('')}</tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `;

    var newWindow = window.open('', '', `width=${window.screen.width},height=${window.screen.height}`);
    newWindow.document.write(tableHtml)
  }

  return (
    <>
      {customerSelect && <OrdersByCustomers id={customerSelect} clear={() => setCustomerSelect(null)} />}
      <NavBar>
        <div className="container-fluid">

          {/* <h4 className="card-title p-1 bg-dark-blue-1 text-white rounded" style={{ display: 'flex', alignItems: 'center', gap: 10, fontSize: '1.7rem' }}>
                <FaUserAlt /> Clientes
              </h4> */}
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}> <BiGroup color='white' className="mb-1" size={22} /> Clientes</p>
          </div>

          <div className="mt-2 mt-2">
            <div className="row">
              <div className="col-sm-8 d-flex align-items-center justify-content-end gap-2">
                <div className="w-100">
                  <label htmlFor="">Pesquisa</label>
                  <input
                    type="text"
                    className="form-control form-control-sm w-100"
                    ref={refSearch}
                  />
                </div>

              </div>
              <div className="col-sm-4 row gap-2">
                <button className="btn btn-sm border mt-4 col-sm-5" onClick={() => setSearch(refSearch.current?.value)}>Pesquisar</button>
                <button className="btn btn-sm btn-primary mt-4 col-sm-5" onClick={() => reportInactiveClients()}>Relatório de recuperação de clientes</button>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {data && (
            <div className="table-responsive mt-3">
              <table className="table table-sm" style={{ fontSize: '1rem' }}>
                <thead>
                  <tr>
                    <th >Nome</th>
                    <th >Telefone</th>
                    <th >Ultimo pedido</th>
                    <th >Quantidade de pedido</th>
                    <th >Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((customer) => {
                    return (
                      <tr key={customer.id}>
                        <td>{customer.name}</td>
                        <td>{customer.phone.replace('_', '')}</td>
                        <td>{customer.lastOrder && DateTime.fromISO(customer.lastOrder).plus({ hours: -3 }).toFormat("dd/MM/yyyy HH:mm:ss")}</td>
                        <td>Pedidos: {customer.countOrder}</td>
                        <td>
                          <button
                            className="btn btn-sm"
                            onClick={() => setCustomerSelect(customer.id)}>
                            <FaSearch />
                          </button>
                          {/* <button className="btn btn-sm ms-1" onClick={() => destroy(customer.id)}><FaTrashAlt /></button> */}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </NavBar>
    </>
  )
}


