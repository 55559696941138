import { useContext, useEffect } from "react"
import { BiTable } from "react-icons/bi"
import _ from 'lodash'

import { api } from "../../api/api";
import { GlobalContext } from "../../context/GlobalContext";
import { ModalTable } from "./ModalTable";
import { Toast } from "../../utils/Toast";
import './style.css'
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_SELECT_TABLE, SELECT_TABLE } from "../../stores/reducers/TableReducers";

export function TableComponent ({ setCountTables }) {
  const { isAccess } = useContext(GlobalContext)
  const dispatch = useDispatch()

  const dataTables = useSelector(state => state.table.data)
  const selectTable = useSelector(state => state.table.table)

  async function openTable (value) {
    dispatch(SELECT_TABLE(value))
    const myModal = new window.bootstrap.Modal(document.getElementById('ModalTable'), {
      keyboard: false
    })

    myModal.show()
  }

  async function createTable () {
    if (!isAccess('mesas', 'criar_mesa')) {
      return Toast.warning('Você não tem permissão para criar mesa');
    }

    const quantity = await Toast.getValue('Digite a quantidade de mesa do seu estabelecimento', null, null, null, 'number')

    if (!quantity) {
      return
    }

    await api.post('/tables', { quantity })
  }

  // async function transferCommand (idCommand, idTableSelect) {
  //   if (!isAccess('mesas', 'transferir_comanda')) {
  //     return Toast.warning('Você não tem permissão para transferir comanda de mesa');
  //   }

  //   if (!idCommand) {
  //     return
  //   }
  //   document.getElementById('modaltable-close').click()

  //   const tables = _.orderBy(dataTables.filter(e => e.id !== idTableSelect).map(e => Number(e.name)))
  //   const tableDestianion = await Toast.selectValue('Para qual mesa deseja transferir?', '', tables)
  //   const tableSelect = dataTables.find(e => e.name === tables[tableDestianion])

  //   if (!tableSelect || !tableDestianion) {
  //     return
  //   }

  //   await api.get(`/commands/transfer/${idCommand}/${tableSelect.id}`)
  //   Toast.success("Comanda transferida com sucesso.")
  // }

  useEffect(() => {
    setCountTables(dataTables.filter(e => Boolean(e.available) === false)?.length ?? 0)

  }, [dataTables, dataTables.length, setCountTables])

  return (
    <>
      <ModalTable
        payload={selectTable}
        clear={() => dispatch(CLEAR_SELECT_TABLE())} />

      <div id="container-table" className="container-fluid" style={{ maxHeight: 'calc(100vh - 250px)' }}>
        <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
          <p className="text-white" style={{ fontSize: '1.3rem' }}>
            <BiTable color='white' size={22} className="mb-1" /> Controle de mesas
          </p>
          {isAccess() !== 'garcom' && (
            <button className="btn btn-sm bg-light btn-circle" onClick={() => createTable()}>
              <i className="fas fa-plus"></i>
            </button>
          )}
        </div>

        <br />

        <div className="container-orders-mesa" style={{ display: 'flex', alignItems: 'flex-start', maxHeight: window.screen.width < 1380 ? 350 : 600, gap: 10 }}>
          <div className="tables-columns">
            {_.orderBy(dataTables.map(e => {
              return {
                ...e,
                name: Number(e.name),
              }
            }), 'name', 'asc')?.map(value => {
              return (
                <div
                  key={value.id}
                  onClick={async () => await openTable(value)}
                  style={{ height: 100, width: 100, display: 'flex', justifyContent: 'center', alignItems: "center" }}
                  className={`pointer card rounded ${value.available ? 'bg-success text-white' : 'bg-danger text-white'}`}
                >

                  <h3 className="card-title">
                    {value.name}
                  </h3>
                </div>
              )
            })}
          </div>
        </div>
      </div >
    </>
  )
}