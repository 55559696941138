import { SocketContext } from "../context/SocketContext"

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { io } from 'socket.io-client';
import { useCompany } from "../hooks/useCompany";
import { useIntegrationWhatsApp } from "../hooks/useIntegrationWhatsApp";
import { PaymentMethod } from "../utils/PaymentMethods";
import { api } from "../api/api";
import { sendPrint } from "../reporting/print";
import { ADD_NEW_ORDERS, SET_ORDERS, UPDATE_ORDER } from "../stores/reducers/OrderReducers";
import { ADD_NEW_ORDERS_IFOOD, SET_ORDERS_IFOOD, UPDATE_ORDER_IFOOD } from "../stores/reducers/IfoodOrders";
import { ADD_NEW_COMMAND_TABLE, ADD_NEW_TABLE, CLOSE_TABLE, DELETE_COMMAND_TABLE, SET_TABLES_DATA, UPDATE_COMMAND } from "../stores/reducers/TableReducers";



export const SocketProvider = ({ children }) => {
  const socket = io(process.env.REACT_APP_BACKEND, {
    autoConnect: false,
    extraHeaders: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
  });

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const { configurations } = useCompany()
  const { sendMessage } = useIntegrationWhatsApp()

  useEffect(() => {
    if (!configurations?.id) {
      return
    }

    socket.on('preload-orders', async ({ dataOrders, dataOrdersIfood, dataTables }) => {
      if (window.location.pathname === '/' || window.location.pathname === '/register') {
        setLoading(false)
        return dispatch(SET_ORDERS([]))
      }

      dispatch(SET_ORDERS(dataOrders))
      dispatch(SET_ORDERS_IFOOD(dataOrdersIfood))


      const notifyOrderPedding = dataOrders?.filter((valueItem) => valueItem.status === 'aguardando' && valueItem.typeOrder === 'delivery')

      if (notifyOrderPedding.length > 0) {
        if (configurations?.sound) {
          document.getElementById('audio-new-order').play()
        }

        if (configurations?.autoPrintOrder) {
          for (const order of notifyOrderPedding) {
            if (order.paymentMethod === PaymentMethod.pix) {
              continue;
            } else {
              const response = await api.put('/orders/' + order.id, { status: 'aprovado' })

              if (response.status === 200) {
                await sendMessage(order.phone, 'messageAccepted', order)
                setTimeout(async () => {
                  await sendPrint('order', {}, order.id)
                }, 1000);
              }
            }
          }
        }
      }

      dispatch(SET_TABLES_DATA(dataTables))

      setLoading(false)
    });

    return () => {
      socket.off('preload-orders');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations]);

  useEffect(() => {
    if (!configurations?.id) {
      return
    }

    socket.on('new-order', async (order) => {
      if (configurations?.sound && order.typeOrder === 'delivery') {
        document.getElementById('audio-new-order').play()
      }

      if (configurations?.autoPrintOrder) {
        if (order.paymentMethod === PaymentMethod.pix) {
          dispatch(ADD_NEW_ORDERS(order))
          return
        } else {
          const response = await api.put('/orders/' + order.id, { status: 'aprovado' })

          if (response.status === 200) {
            await sendMessage(order.phone, 'messageAccepted', order)
            setTimeout(async () => {
              await sendPrint('order', {}, order.id)
            }, 1000);

            dispatch(ADD_NEW_ORDERS({
              ...order,
              status: 'aprovado'
            }))
          }
        }
      }
    });

    socket.on('new-order-ifood', async (order) => {
      if (configurations?.sound) {
        document.getElementById('audio-new-order').play()
      }

      if (configurations?.autoPrintOrder) {
        await api.get('/ifood/print?id=' + order.id).catch(console.log)
      }

      dispatch(ADD_NEW_ORDERS_IFOOD(order))
    });

    socket.on('new-order-updated', async (order) => {
      dispatch(UPDATE_ORDER(order))
    })

    socket.on('new-order-ifood-updated', async (order) => {
      console.log(order)
      dispatch(UPDATE_ORDER_IFOOD(order))
    })

    socket.on('new-command', async (command) => {
      dispatch(ADD_NEW_COMMAND_TABLE(command))
    })

    socket.on('new-table', async (table) => {
      dispatch(ADD_NEW_TABLE(table))
    })

    socket.on('delete-command', async (command) => {
      dispatch(DELETE_COMMAND_TABLE(command.idCommand))
    })

    socket.on('close-table', async (table) => {
      dispatch(CLOSE_TABLE(table.tableId))
    })

    socket.on('updated-command', async (command) => {
      dispatch(UPDATE_COMMAND(command))
    })

    return () => {
      socket.off('new-order');
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations])

  useEffect(() => {
    if (!configurations?.id) {
      return
    }
    socket.connect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations,])

  return (
    <SocketContext.Provider value={{ loading }}>
      {children}
    </SocketContext.Provider>
  )
}