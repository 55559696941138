import { api } from '../api/api'
import axios from 'axios'
import { Toast } from '../utils/Toast';

if (!JSON.parse(localStorage.getItem('user') || '{}')) {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  if (window.location.pathname !== '/' && window.location.pathname !== '/register') {
    window.location.href = '/'
  }
}

export async function sendPrint (type, data, idOrder) {
  const user = JSON.parse(localStorage.getItem('user') || '{}')

  const response = await api.post('/orders/print', {
    type,
    data,
    idOrder,
    isGetHTML: !(user?.isPrint || false)
  })

  if (response.data) {
    var newWindow = window.open('', '', `width=${window.screen.width},height=${window.screen.height}`);
    newWindow.document.write(response.data)
    newWindow.print()
    newWindow.close()
  }
}

export async function sendPrintSafeRegister (id) {
  const user = JSON.parse(localStorage.getItem('user') || '{}')

  const isPrint = !(user?.isPrint || false)

  const response = await api.get(`/safe/print/registers/${id}?isGetHTML=${isPrint}`)

  if (response.data) {
    var newWindow = window.open('', '', `width=${window.screen.width},height=${window.screen.height}`);
    newWindow.document.write(response.data)
    newWindow.print()
    newWindow.close()
  }
}

export async function sendPrintSafeClose (id) {
  const user = JSON.parse(localStorage.getItem('user') || '{}')

  const isPrint = !(user?.isPrint || false)
  const response = await api.get(`/safe/print/close/${id}?isGetHTML=${isPrint}`)

  if (response.data) {
    var newWindow = window.open('', '', `width=${window.screen.width},height=${window.screen.height}`);
    newWindow.document.write(response.data)
    newWindow.print()
    newWindow.close()
  }
}

export async function sendPrintNota (url = '', codePrint, namePrint) {
  const user = JSON.parse(localStorage.getItem('user') || '{}')

  const isPrint = !(user?.isPrint || false)

  if (!url || !codePrint || !namePrint || isPrint) {
    Toast.warning('Configure a impressora padrão por favor.')
    window.open(url, '_blank')
    return;
  }

  const response = await axios.get(url)

  await api.post('/printing', {
    codePrint,
    namePrint,
    body: response.data
  })
}

