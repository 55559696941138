import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { maskCurrency } from "../../utils/utils";

import { ModalOrders } from "../../components/Modals/Orders/index";
import { ModalRefusedOrder } from "../../components/Modals/RefusedOrder";
import { NavBar } from "../../components/NavBar";
import { OrderContext } from "../../context/OrderContext";
import _ from 'lodash'
import { BsQuestionCircleFill } from "react-icons/bs";
import { HomeHelper } from "../../components/Modals/Helpers/HomeHelper";
import { Card } from "./components/Card";
import './style.css';
import { RouteMaps } from "../../components/Modals/RouteMaps";
import { FaCashRegister, FaLocationArrow, FaPlus } from "react-icons/fa";
import { useCompany } from "../../hooks/useCompany";
import { useIntegrationWhatsApp } from "../../hooks/useIntegrationWhatsApp";
import { TableComponent } from "../../components/Tables";
import { Contract } from "../../components/Modals/Contract";
import { GlobalContext } from "../../context/GlobalContext";
import { Toast } from "../../utils/Toast";
import { api } from "../../api/api";
import useSWR, { useSWRConfig } from "swr"
import { sendPrint } from "../../reporting/print";
import { CardIfood } from "./components/CardIfood";
import { ModalOrderIfood } from "../../components/Modals/OrderIfood";
import { printPage } from "../../services/OrderService";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SocketContext } from "../../context/SocketContext";

export function HomePage () {
  const { isAccess, user } = useContext(GlobalContext)
  const { mutate } = useSWRConfig()
  const [countTables, setCountTables] = useState(0)
  const { sendMessage } = useIntegrationWhatsApp()
  const { updated, orders, selectOrder } = useContext(OrderContext)
  const { loading: loadingSocket } = useContext(SocketContext)

  const { configurations } = useCompany()
  const navigate = useNavigate()

  const [orderIfood, setOrderIfood] = useState({})

  const dataOrdersIfood = useSelector(state => state?.ifoodOrders?.orders)

  const { data: dataProductsNotSeen } = useSWR('/product-order/products/not-seen', async () => {
    const response = await api.get('/product-order/products/not-seen')

    const bodyProductNotSeen = response.data
    const groupNumOrder = _.groupBy(bodyProductNotSeen, 'numOrder')
    const groupedByName = _.groupBy(bodyProductNotSeen.filter(k => k.name), 'name');
    const groupedByIdOrder = _.groupBy(bodyProductNotSeen.filter(k => k.name), 'idOrder');

    if (bodyProductNotSeen.length > 0) {
      if (configurations?.sound) {
        document.getElementById('audio-new-order').play()
      }

      Toast.success('Pedidos atualizados e novos produtos adicionado', 2000)
    }

    return {
      groupNumOrder,
      groupedByName,
      groupedByIdOrder
    }
  }, {
    refreshInterval: 20000
  })

  const { data: safeIsOpen } = useSWR('/safe/is/open', async () => {
    const res = await api.get('/safe/is/open')

    return res.data
  }, {})

  function getQuantityOrdersWithStatus (status) {
    return orders?.filter((value) => value.typeOrder === 'delivery' && value.status === status)?.length ?? 0
  }

  async function createOrder () {
    if (!isAccess('pedidos', 'create_pedido_balcao')) {
      return Toast.warning('Você não tem permissão para criar um pedido de balcão.')
    }

    const confirmation = await Toast.whenTypeOrder('Criar pedido?', 'Não', 'Sim')

    if (confirmation === true) { // entrega imediata
      const response = await api.post('/orders', {
        methodDelivery: 'retirada',
        name: 'Pedido balcão',
        phone: '',
        status: 'aprovado',
        typeOrder: 'frente-de-caixa',
        paymentMethod: 'money'
      })

      await api.get('/orders/' + response.data.id)

      selectOrder(response.data.id)

      await mutate('/orders')

      var myModal = new window.bootstrap.Modal(document.getElementById('modalOrder'), {})
      myModal.show()
      return
    }
  }

  useLayoutEffect(() => {
    if (localStorage.getItem('modal_introducao') <= 3 && isAccess()) {
      try {
        document.getElementById('open-modal').click()
        localStorage.setItem('modal_introducao', (Number(localStorage.getItem('modal_introducao')) ?? 0) + 1)
      } catch (error) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (document.getElementById('contractHome')) {
      new window.bootstrap.Modal(document.getElementById('contractHome'), {}).toggle()
    }
  }, [])

  useEffect(() => {
    if (orderIfood?.id) {
      new window.bootstrap.Modal(document.getElementById('modalOrderIfood'), {}).toggle()
    }
  }, [orderIfood])

  return (
    <>
      {(localStorage.getItem('modal-contract') === 'false' && localStorage.getItem('modal_introducao') > 3) && <Contract />}
      <ModalOrders printPage={printPage} />
      <ModalRefusedOrder />
      {user.plan === 'complet' && <ModalOrderIfood order={orderIfood} clear={() => setOrderIfood({})} />}
      <HomeHelper />
      {(configurations.googleKey && user.plan === 'complet') && <RouteMaps />}
      <NavBar>

        {loadingSocket && (
          <div className="container-fluid" style={{ height: '90vh', width: '80vw' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
              <div className="spinner-border text-success" role="status" style={{ width: 50, height: 50 }}>
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}

        {!loadingSocket && (
          <>
            {isAccess() !== 'garcom' && (
              <>
                <div className="container-fluid">
                  <div className="mb-0 mt-3 me-2" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 20 }}>
                    {isAccess('pedidos', 'create_pedido_balcao') && (
                      <span className="rounded p-1 pointer" onClick={() => createOrder()}>
                        Novo pedido <FaPlus size={25} />
                      </span>
                    )}

                    <span className="rounded p-1 pointer" onClick={() => navigate('/safe')}>
                      {safeIsOpen ? 'Fechar caixa' : 'Abrir caixa'} <FaCashRegister className="mb-1" size={25} color={safeIsOpen ? 'red' : 'green'} />
                    </span>

                    {((configurations.googleKey && user.plan === 'complet') && <span className="pointer" data-bs-toggle="modal" data-bs-target="#modalRoutesMaps">Melhor trajeto <FaLocationArrow size={25} /> </span>)}
                    <span className="pointer" id="open-modal" data-bs-toggle="modal" data-bs-target="#helperHome">Central de ajuda <BsQuestionCircleFill col size={25} /> </span>
                  </div>
                </div>
                <ul className="nav nav-tabs container-fluid" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                      Delivery / Balcão <span className="badge bg-dark-blue-1"> {orders?.filter(e => e.typeOrder === 'delivery')?.length ?? 0}</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                      Mesas <span className="badge bg-dark-blue-1"> {countTables}</span>
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className='container-fluid card-pai-order mt-4' >
                      <div className="card card-order rounded">
                        <div className="p-2" style={{ backgroundColor: '#13293d', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                          <h4 className="cart-text text-cart m-0 text-white" style={{ fontSize: '1rem' }}>Em aberto</h4>
                          <div className="bg-white " style={{ width: 22, height: 22, borderRadius: 22, fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            {getQuantityOrdersWithStatus('aguardando') ?? 0}
                          </div>
                        </div>
                        <div className="card-body" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
                          {_.orderBy(orders, (a) => a.numOrder, 'desc').filter((order) => order.status === 'aguardando' && ['frente-de-caixa', 'delivery'].includes(order.typeOrder)).map((order) => {
                            return (
                              <Card
                                id={order.id}
                                order={order}
                                numOrder={order?.numOrder}
                                methodDelivery={order.methodDelivery}
                                key={order.id}
                                name={order.name}
                                pricing={maskCurrency(order.total)}
                                createdAt={order.createdAt}
                                paymentMethod={order.paymentMethod}
                                isUpdated={(dataProductsNotSeen?.groupNumOrder[order.numOrder]?.length ?? 0) > 0}
                                next={async () => {
                                  if (!configurations?.autoPrintOrder) {
                                    await updated(order.id, 'status', 'aprovado', false)

                                    if (order.typeOrder === 'delivery') {
                                      await sendMessage(order.phone, 'messageAccepted', order)
                                    }
                                  }
                                }}
                                selectOrder={() => selectOrder(order.id)}
                                print={async () => await sendPrint('order', {}, order.id)}
                                address={`${order.address}, ${order.number}, ${order.complement}`}
                                status={order.status}
                                refused={async () => {
                                  await selectOrder(order.id)
                                  new window.bootstrap.Modal(document.getElementById('modalRefusedOrder'), {}).toggle()
                                }}
                              />
                            )
                          })}

                          {_.orderBy(dataOrdersIfood, (a) => a.createdAt, 'asc').filter((o) => o.status === 'aguardando').map(e => {
                            const pedido = e.payload[0]
                            return (
                              <>
                                <CardIfood
                                  id={e.id}
                                  key={e.id}
                                  numOrder={pedido.displayId}
                                  name={pedido.customer.name}
                                  pricing={maskCurrency(pedido.total.orderAmount)}
                                  createdAt={e.createdAt}
                                  address={`${pedido?.delivery?.deliveryAddress?.formattedAddress}`}
                                  status={e.status}
                                  selectOrder={() => setOrderIfood(e)}
                                  next={async () => await api.get('/ifood/confirm/' + pedido.id)}
                                  orderType={pedido?.orderType}
                                  order={e}
                                />
                              </>
                            )
                          })}


                          {orders?.filter((order) => order.status === 'aguardando' && ['frente-de-caixa', 'delivery'].includes(order.typeOrder)).length === 0 &&
                            <div style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <p className="fs-5 fw-bold text-secondary">
                                Sem pedidos
                              </p >
                            </div>
                          }
                        </div>
                      </div>
                      <div className="card card-order">
                        <div className="p-2" style={{ backgroundColor: '#006494', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                          <h4 className="cart-text text-cart m-0 text-white" style={{ fontSize: '1rem' }}>Em preparo</h4>
                          <div className="bg-white " style={{ width: 22, height: 22, borderRadius: 22, fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: "center" }}>{getQuantityOrdersWithStatus('aprovado')}</div>
                        </div>
                        <div className="card-body" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
                          {_.orderBy(orders, (a) => a.numOrder, 'desc').filter((order) => order.status === 'aprovado' && ['frente-de-caixa', 'delivery'].includes(order.typeOrder)).map((order) => {
                            return (
                              <Card
                                id={order.id}
                                order={order}
                                paymentMethod={order.paymentMethod}
                                numOrder={order?.numOrder}
                                methodDelivery={order.methodDelivery}
                                key={order.id}
                                name={order.name}
                                pricing={maskCurrency(order.total)}
                                createdAt={order.createdAt}
                                isUpdated={(dataProductsNotSeen?.groupNumOrder[order.numOrder]?.length ?? 0) > 0}
                                next={async () => {
                                  await updated(order.id, 'status', 'em_entrega', false)
                                  if (order.typeOrder === 'delivery') {
                                    await sendMessage(order.phone, 'messageSendDelivery', order)
                                  }
                                }}
                                selectOrder={() => selectOrder(order.id)}
                                print={async () => await sendPrint('order', {}, order.id)}
                                status={order.status}
                                address={`${order.address}, ${order.number}, ${order.complement}`}
                                responseWebmania={order?.statusNota === 'Emitida' ? order.response : null}
                              />
                            )
                          })}

                          {_.orderBy(dataOrdersIfood, (a) => a.createdAt, 'asc').filter((o) => o.status === 'aprovado').map(e => {
                            const pedido = e.payload[0]
                            return (
                              <>
                                <CardIfood
                                  id={e.id}
                                  key={e.id}
                                  numOrder={pedido?.displayId}
                                  name={pedido?.customer?.name}
                                  pricing={maskCurrency(pedido?.total?.orderAmount)}
                                  createdAt={e.createdAt}
                                  address={`${pedido?.delivery?.deliveryAddress?.formattedAddress}`}
                                  status={e.status}
                                  selectOrder={() => setOrderIfood(e)}
                                  next={async () => await api.get('/ifood/dispach/' + pedido?.id)}
                                  orderType={pedido?.orderType}
                                  order={e}
                                />
                              </>
                            )
                          })}


                          {orders?.filter((order) => order.status === 'aprovado' && ['frente-de-caixa', 'delivery'].includes(order.typeOrder)).length === 0 &&
                            <div style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <p className="fs-5 fw-bold text-secondary">
                                Sem pedidos
                              </p >
                            </div>
                          }
                        </div>
                      </div>
                      <div className="card card-order">
                        <div className="p-2" style={{ backgroundColor: '#403b73', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                          <h4 className="cart-text text-cart m-0 text-white" style={{ fontSize: '1rem' }}>Em entrega</h4>
                          <div className="bg-white " style={{ width: 22, height: 22, borderRadius: 22, fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            {getQuantityOrdersWithStatus('em_entrega')}
                          </div>
                        </div>
                        <div className="card-body" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
                          {_.orderBy(orders, (a) => a.numOrder, 'desc').filter((order) => ['em_entrega', 'pronto_para_retirada'].includes(order.status) && ['frente-de-caixa', 'delivery'].includes(order.typeOrder)).map((order) => {
                            return (
                              <Card
                                id={order.id}
                                order={order}
                                numOrder={order?.numOrder}
                                paymentMethod={order.paymentMethod}
                                methodDelivery={order.methodDelivery}
                                key={order.id}
                                name={order.name}
                                pricing={maskCurrency(order.total)}
                                createdAt={order.createdAt}
                                isUpdated={(dataProductsNotSeen?.groupNumOrder[order.numOrder]?.length ?? 0) > 0}
                                next={async () => {
                                  await updated(order.id, 'status', 'finalizado', false)
                                  if (order.typeOrder === 'delivery') {
                                    await sendMessage(order.phone, 'messageSendReview', order)
                                  }
                                }}
                                selectOrder={() => selectOrder(order.id)}
                                print={async () => await sendPrint('order', {}, order.id)}
                                status={order.status}
                                address={`${order.address}, ${order.number}, ${order.complement}`}
                                responseWebmania={order?.statusNota === 'Emitida' ? order.response : null}
                              />
                            )
                          })}

                          {_.orderBy(dataOrdersIfood, (a) => a.createdAt, 'asc').filter((o) => ['em_entrega', 'pronto_para_retirada'].includes(o.status)).map(e => {
                            const pedido = e.payload[0]
                            return (
                              <>
                                <CardIfood
                                  id={e.id}
                                  key={e.id}
                                  numOrder={pedido.displayId}
                                  name={pedido.customer.name}
                                  pricing={maskCurrency(pedido.total.orderAmount)}
                                  createdAt={e.createdAt}
                                  address={`${pedido?.delivery?.deliveryAddress?.formattedAddress}`}
                                  status={e.status}
                                  selectOrder={() => setOrderIfood(e)}
                                  orderType={pedido?.orderType}
                                  order={e}
                                />
                              </>
                            )
                          })}
                          {orders?.filter((order) => order.status === 'em_entrega' && ['frente-de-caixa', 'delivery'].includes(order.typeOrder)).length === 0 &&
                            <div style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <p className="fs-5 fw-bold text-secondary">
                                Sem pedidos
                              </p >
                            </div>
                          }
                        </div>
                      </div>
                      <div className="card card-order">
                        <div className="p-2 bg-success" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                          <h4 className="cart-text text-cart m-0 text-white" style={{ fontSize: '1rem' }}>Finalizado</h4>
                          <div className="bg-white " style={{ width: 22, height: 22, borderRadius: 22, fontSize: '1rem', display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                            {getQuantityOrdersWithStatus('finalizado')}
                          </div>
                        </div>
                        <div className="card-body" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 250px)' }}>
                          {_.orderBy(orders, (a) => a.numOrder, 'desc').filter((order) => ['finalizado', 'cancelado', 'recusado'].includes(order.status) && ['frente-de-caixa', 'delivery'].includes(order.typeOrder)).sort((a, b) => a.status - b.status).map((order) => {
                            return (
                              <Card
                                id={order.id}
                                order={order}
                                paymentMethod={order.paymentMethod}
                                numOrder={order?.numOrder}
                                methodDelivery={order.methodDelivery}
                                key={order.id}
                                name={order.name}
                                pricing={maskCurrency(order.total)}
                                createdAt={order.createdAt}
                                next={null}
                                selectOrder={() => selectOrder(order.id)}
                                print={async () => await sendPrint('order', {}, order.id)}
                                address={`${order.address}, ${order.number}, ${order.complement}`}
                                status={order.status}
                              />
                            )
                          })}

                          {_.orderBy(dataOrdersIfood, (a) => a.createdAt, 'asc').filter((o) => ['finalizado', 'cancelado', 'recusado'].includes(o.status)).map(e => {
                            const pedido = e.payload[0]
                            return (
                              <>
                                <CardIfood
                                  id={e.id}
                                  key={e.id}
                                  numOrder={pedido.displayId}
                                  name={pedido.customer.name}
                                  pricing={maskCurrency(pedido.total.orderAmount)}
                                  createdAt={e.createdAt}
                                  address={`${pedido?.delivery?.deliveryAddress?.formattedAddress}`}
                                  status={e.status}
                                  selectOrder={() => setOrderIfood(e)}
                                  orderType={pedido?.orderType}
                                  order={e}
                                />
                              </>
                            )
                          })}

                          {orders?.filter((order) => ['finalizado', 'cancelado', 'recusado'].includes(order.status) && ['frente-de-caixa', 'delivery'].includes(order.typeOrder)).length === 0 &&
                            <div style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <p className="fs-5 fw-bold text-secondary">
                                Sem pedidos
                              </p >
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div className="container-fluid card-pai-order" >
                      <TableComponent setCountTables={setCountTables} />
                    </div>
                  </div>
                </div>
              </>
            )}


            {isAccess() === 'garcom' && (
              <TableComponent setCountTables={setCountTables} />
            )}
          </>
        )}
      </NavBar>
    </>
  )
}
