import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  orders: [],
}

export const IfoodOrders = createSlice({
  name: 'ifoodOrder',
  initialState: initialState,
  reducers: {
    SET_ORDERS_IFOOD: (state, action) => {
      if (action.payload)
        state.orders = action.payload
    },
    ADD_NEW_ORDERS_IFOOD: (state, action) => {
      state.orders.push(action.payload)
    },
    UPDATE_ORDER_IFOOD: (state, action) => {
      state.orders = state.orders.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          }
        }
        return item
      })

      state.orderSelect = state.orders.find((item) => item.id === action.payload.id)
    },
  }
})

export const { SET_ORDERS_IFOOD, ADD_NEW_ORDERS_IFOOD, UPDATE_ORDER_IFOOD } = IfoodOrders.actions

export default IfoodOrders.reducer