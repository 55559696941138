/* eslint-disable jsx-a11y/anchor-is-valid */
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { MenuPage } from "./pages/Menu/index";
import { MotoboyPage } from "./pages/Motoboy";
import { MotoboyProvider } from "./providers/MotoboyProvider";
import { OrderProvider } from "./providers/OrderProvider";
import { MenuProvider } from "./providers/MenuProvider";
import { CompanyPage } from "./pages/Company";
import { CardProvider } from "./providers/CardProvider";
import { CardPage } from "./pages/Card";
import { PaymentMethodPage } from "./pages/PaymentMethod";
import { PaymentMethodProvider } from "./providers/PaymentMethodProvider";
import { LoginPage } from "./pages/Login/indexV2";
import { CustomerPage } from "./pages/Customer";
import { HomePage } from "./pages/Home";
import { ReportPage } from "./pages/Report";
import { useContext, useEffect } from "react";
import { CrossSellingPage } from "./pages/CrossSelling";
import { CupomPage } from "./pages/Cupom";
import { MethodDelivery } from "./pages/MethodDelivery";
import { RatingOrdersPage } from "./pages/RatingOrders";
import { Integration } from "./pages/Integration";
import { RegisterPage } from "./pages/Register";
import { SafePage } from "./pages/Safe";
import { ProductPage } from "./pages/Product";
import { ProductProvider } from "./providers/ProductProvider";
import { StockPage } from "./pages/Stock";
import { FiscalPage } from "./pages/fiscal";
import { FiscalProvider } from "./providers/FiscalProvider";
import { PageUsers } from "./pages/Users";
import { GlobalContext } from "./context/GlobalContext";
import { SectorPage } from "./pages/Sector";

function App () {
  const { setUser } = useContext(GlobalContext)

  useEffect(() => {
    const userLocalStorage = localStorage.getItem('user')
    if (userLocalStorage) {
      try {
        setUser(JSON.parse(userLocalStorage))
      } catch (error) { }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <audio src="https://pedidos-images.s3.amazonaws.com/default/new_order.mp3" id="audio-new-order"></audio>
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<LoginPage />} exact />
          <Route path="/register" element={<RegisterPage />} exact />
          <Route path="/safe" element={(
            <OrderProvider>
              <SafePage />
            </OrderProvider>
          )} exact />


          <Route path="/customer" element={<CustomerPage />} exact />
          <Route path="/sectors" element={<SectorPage />} exact />
          <Route path="/fiscal" element={(
            <OrderProvider>
              <FiscalProvider>
                <FiscalPage />
              </FiscalProvider>
            </OrderProvider>
          )} exact />
          <Route path="/stock" element={<StockPage />} exact />
          <Route path="/product" element={(
            <MenuProvider>
              <ProductProvider>
                <ProductPage />
              </ProductProvider>
            </MenuProvider>
          )} exact />
          <Route path="/home" element={
            (
              <OrderProvider>
                <HomePage />
              </OrderProvider>
            )
          } exact />
          <Route path="/menu" element={(
            <MenuProvider>
              <ProductProvider>
                <MenuPage />
              </ProductProvider>
            </MenuProvider>
          )} exact />
          <Route path="/motoboy" element={(
            <MotoboyProvider>
              <MotoboyPage />
            </MotoboyProvider>
          )} exact />
          <Route path="/card" element={(
            <CardProvider>
              <CardPage />
            </CardProvider>
          )} exact />
          <Route path="/payment-method" element={(
            <PaymentMethodProvider>
              <PaymentMethodPage />
            </PaymentMethodProvider>
          )} exact />
          <Route path="/company" element={(
            <CompanyPage />
          )} exact />
          <Route path="/report" element={(
            <OrderProvider>
              <ReportPage />
            </OrderProvider>
          )} exact />
          <Route path="/cross-selling" element={(
            <CrossSellingPage />
          )} exact />
          <Route path="/cupom" element={(
            <CupomPage />
          )} exact />
          <Route path="/method-delivery" element={(
            <MethodDelivery />
          )} exact />
          <Route path="/rating" element={(
            <OrderProvider>
              <RatingOrdersPage />
            </OrderProvider>
          )} exact />

          <Route path="/integration" element={(
            <Integration />
          )} exact />

          <Route path="/users" element={<PageUsers />} exact />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
