import { configureStore } from '@reduxjs/toolkit'

import ConfigurationReducer from './reducers/ConfiguratonReducers'
import DistrictsReducer from './reducers/DistrictsReducers'
import MotoboyReducers from './reducers/MotoboyReducers'
import CompanyReducer from './reducers/CompanyReducers'
import OrderReducers from './reducers/OrderReducers'
import SafeReducers from './reducers/SafeReducers'
import CategoryReducers from './reducers/CategoryReducers'
import SectorsReducers from './reducers/SectorReducers'
import IfoodOrders from './reducers/IfoodOrders'
import TableReducers from './reducers/TableReducers'

export default configureStore({
  reducer: {
    category: CategoryReducers,
    company: CompanyReducer,
    configuration: ConfigurationReducer,
    district: DistrictsReducer,
    ifoodOrders: IfoodOrders,
    motoboy: MotoboyReducers,
    order: OrderReducers,
    safe: SafeReducers,
    sector: SectorsReducers,
    table: TableReducers
  }
})