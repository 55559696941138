import { DateTime } from "luxon";
import { BsPrinter } from "react-icons/bs";
import { numOrderFormat } from "../../../utils/utils";
import { FaArrowRight, FaMapMarkerAlt, FaPrint } from "react-icons/fa";
import { IoMdOpen } from "react-icons/io";
import { CgDanger } from 'react-icons/cg'
import { PaymentMethod } from "../../../utils/PaymentMethods";
import { Toast } from "../../../utils/Toast";
import { api } from "../../../api/api";
import { useDispatch } from "react-redux";
import { SELECT_ORDER } from "../../../stores/reducers/OrderReducers";
import { sendPrintNota } from "../../../reporting/print";
import { useCompany } from "../../../hooks/useCompany";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { GrDocumentNotes } from "react-icons/gr";

export function Card ({ order, commandCode, isMesa = false, paymentMethod, isUpdated, address, id, numOrder, name, methodDelivery, pricing, createdAt, next, selectOrder, print, status, responseWebmania, refused }) {
  const dispatch = useDispatch()
  const { configurations } = useCompany()
  const { isAccess, user } = useContext(GlobalContext)


  const date = DateTime.fromISO(createdAt).toFormat("dd/MM - HH:mm")
  const textMethodDelviery = methodDelivery === 'retirada' ? 'Retirada' : 'Entrega'

  const canceled = ['cancelado'].includes(status)

  async function openOrder () {
    const myModal = new window.bootstrap.Modal(document.getElementById('modalOrder'), {})
    myModal.show()
  }

  async function sendCupom (idOrder) {
    try {
      if (user.plan !== 'complet') {
        return Toast.warning('Apenas plano Completo pode emitir cupom fiscal.')
      }
      Toast.success("Estamos emitindo o cupom.")

      const { data } = await api.post('/nfce', { idOrder })

      // if (!configurations.defaultNamePrint || !configurations.defaultCodePrint) {
      //   window.open(data.dataWebMania.danfe)
      // } else {
      //   sendPrintNota(data.dataWebMania.danfe, configurations.defaultCodePrint, configurations.defaultNamePrint)
      // }

      dispatch(SELECT_ORDER({
        ...order,
        response: data
      }))

      Toast.success("Cupom emitido com sucesso.")

      document.getElementById('modalOrderClose').click()
    } catch (error) { }
  }


  return (
    <div key={id} className="card mb-3 shadow-sm" style={{ width: '100%', border: '1px solid #c9c8c8', borderLeft: canceled ? '3px solid red' : '3px solid green' }}>
      <div className="card-body">
        <span style={{ float: 'right' }}>
          <p className="m-0 p-0" style={{ fontSize: '1rem', fontWeight: 'bold' }}>Valor: {pricing}</p>
          <p className="m-0 p-0" style={{ fontSize: '1rem', fontWeight: 'bold' }}>{PaymentMethod[paymentMethod]}</p>
        </span>
        <div >
          <div className="card-text">
            <p className="m-0 p-0" style={{ fontSize: '1rem', fontWeight: 'bold' }}>{commandCode ? commandCode : numOrderFormat(numOrder)} ({date})</p>
            <p className="m-0 p-0" style={{ fontSize: '1rem' }}>Cliente: {name}</p>

            {!isMesa && (
              <div className="mt-2 p-2 rounded" style={{ backgroundColor: "#f8f7f7" }}>
                <p style={{ color: '' }}><FaMapMarkerAlt color="#666" size={20} /> {textMethodDelviery === 'Retirada' ? 'Retirada no local' : address}</p>
              </div>
            )}

            <div className="mt-2">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="btn btn-sm btn-outline-dark" onClick={() => print()}>Imprimir <BsPrinter /></button>

                {isAccess('fiscal', 'emissao') && status !== 'cancelado' && (
                  <div>
                    {((!order?.response)) && (
                      <>
                        <button className="btn btn-sm btn-outline-dark" onClick={() => sendCupom(order.id)}>  Emitir Cupom <GrDocumentNotes /></button>
                      </>
                    )}

                    {(order?.response) && (
                      <>
                        <button className="btn btn-sm btn-outline-dark" onClick={() => sendPrintNota(order.response.danfe, configurations.defaultCodePrint, configurations.defaultNamePrint)}>Imprimir Cupom <FaPrint /></button>
                      </>
                    )}
                  </div>
                )}
                {isUpdated && (
                  <p className="pointer">
                    <CgDanger color="red" size={30} />
                  </p>
                )}
                <button className="btn btn-sm btn-outline-primary" onClick={() => {
                  selectOrder()
                  openOrder()
                }} >Ver pedido <IoMdOpen /></button>
              </div>
              {(next && status !== 'aguardando') && (
                <button className="btn btn-outline-success w-100 mt-2" onClick={() => next()}>
                  Avançar  <FaArrowRight size={20} />
                </button>
              )}

              {(next && status === 'aguardando') && (
                <>
                  <button className="btn btn-outline-success w-100 mt-2" onClick={() => next()}>
                    Aceitar pedido <FaArrowRight size={20} />
                  </button>
                  <button className="btn btn-outline-danger w-100 mt-2" onClick={() => refused()}>
                    Recusar pedido
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

