import { useContext, useEffect, useState } from "react"
import { api } from "../../../api/api"
import { mascaraMoeda, maskCurrency } from "../../../utils/utils"
import { Toast } from "../../../utils/Toast"
import { DateTime } from "luxon"
import { GlobalContext } from "../../../context/GlobalContext"
import useSWR, { useSWRConfig } from "swr"
import { IoPrintSharp } from "react-icons/io5"
import _ from "lodash"
import { sendPrintSafeClose, sendPrintSafeRegister } from "../../../reporting/print"

export function OpenSafe({ isOpen, reload }) {
  const { mutate } = useSWRConfig()
  const [pricingInitialSafe, setPricingInitialSafe] = useState(0)
  const { isAccess } = useContext(GlobalContext)
  const [registers, setRegisters] = useState([])
  const [report, setReport] = useState({
    totalCard: 0,
    totalMoney: 0,
    totalPix: 0,
  })

  useSWR('/safe/load/data', async () => {
    await Promise.all([
      findValues(),
      lastRegisters(),
    ])
  })

  const [state, setState] = useState({
    pricing: pricingInitialSafe || 0,
    description: ''
  })

  async function openSafe() {
    await api.post('/safe/open', {
      pricing: state.pricing,
      description: state.description
    })

    await mutate('/safe/load/data')
    
    await reload()

    setState({ pricing: 0, description: '' })

    Toast.success()
  }



  async function findValues() {
    const response = await api('/safe/reporting')

    const data = response.data[0]

    if (!data) { return; }

    setReport({
      ...report,
      ...data,
    })
  }

  async function lastRegisters() {
    const response = await api('/safe/last/registers')
    setRegisters(response.data)
  }

  useEffect(() => {
    setPricingInitialSafe(state => (_.orderBy(registers, 'close', 'desc').find(x => !!x.close)?.info?.money ?? 0))
  }, [registers])

  useEffect(() => {
    setState({
      ...state,
      pricing: pricingInitialSafe
    })
  }, [pricingInitialSafe])

  return (
    <div className="modal fade" id="modalIsOpenSafe" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="modalIsOpenSafeLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalIsOpenSafeLabel">Movimentações do Caixa</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          {!isOpen && (
            <div className="modal-body">
              <div className="alert alert-warning text-center" role="alert">
                O caixa está fechado, faça a abertura do caixa para iniciar.
              </div>

              <label htmlFor="">Valor de inicio</label>
              <input
                className="form-control form-control-sm"
                type="text"
                disabled={pricingInitialSafe > 0}
                value={maskCurrency(state.pricing)}
                onChange={(e) => setState({ ...state, pricing: mascaraMoeda(e.target.value) })}
              />

              <label htmlFor="">Descrição</label>
              <textarea
                value={state.description}
                onChange={(e) => setState({ ...state, description: e.target.value })}
                className="form-control form-control-sm"
                id="exampleFormControlTextarea1"
                rows="3">
              </textarea>

              {isAccess('caixa', 'open_safe') && (
                <button className="mt-2 btn btn-sm btn-dark-blue" onClick={() => openSafe()}>Abrir caixa</button>
              )}
            </div>
          )}

          {isOpen && (
            <div className="modal-body">
              <p className="bg-dark text-white p-1 rounded" style={{ display: 'flex', justifyContent: 'space-between' }}><p>Registros de caixas</p> <p>(Ultimos 5)</p></p>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Data de abertura</th>
                      <th>Data de fechamento</th>
                      <th>Imprimir Registros</th>
                      <th>Imprimir fechamento</th>
                    </tr>
                  </thead>
                  <tbody>
                    {registers.map((value) => {
                      return (
                        <tr key={value.id}>
                          <td>{value.close ? 'Fechado' : 'Aberto'}</td>
                          <td>{DateTime.fromISO(value.open).toFormat('dd/MM/yyyy HH:mm:ss')}</td>
                          <td>{value.close ? DateTime.fromISO(value.close).toLocal().toFormat('dd/MM/yyyy HH:mm:ss') : '-'}</td>
                          <td className="pointer" onClick={async () => value.close ? sendPrintSafeRegister(value.id) : null}>
                            {value.close && <IoPrintSharp size={20} />}
                          </td>
                          <td className="pointer" onClick={async () => value.close ? sendPrintSafeClose(value.id) : null}>
                            {value.close && <IoPrintSharp size={20} />}
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </div>

            </div>
          )}
        </div>
      </div>
    </div>
  )
}