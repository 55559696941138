import { createSlice } from '@reduxjs/toolkit'

export const ConfigurationReducer = createSlice({
  name: 'configuration',
  initialState: {
    data: {
      id: '',
      pricingMinDelivery: 0,
      activeRetirada: false,
      activeRating: false,
      idStore: '',
      googleKey: '',
      messagePrint: '',
      sound: false,
      autoPrintBalcao: false,
      autoPrintMesa: false,
      autoPrintDelivery: false,
      autoPrintOnAddProduct: false,
      autoPrintOrder: false,
      defaultNamePrint: '',
      defaultCodePrint: '',
      printCancelProductOrder: false,
      printCancelOrder: false,
      automaticProductAcceptance: false,
      autoPrintIfood: false,
      discountDelivery: {
        active: false,
        discounts: []
      },
      cor: {
        primary: '#212529',
        button: '#212529',
        alert: '#212529'
      }
    }
  },
  reducers: {
    SET_CONFIGURATION: (state, action) => {
      state.data = action.payload
    },
    UPDATE_CONFIGURATION: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload
      }
    },
  }
})


export const { SET_CONFIGURATION, UPDATE_CONFIGURATION } = ConfigurationReducer.actions

export default ConfigurationReducer.reducer