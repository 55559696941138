import { useContext, useEffect, useRef, useState } from "react"
import { FaAlignJustify, FaAngleDown, FaEdit } from "react-icons/fa";

import Swal from "sweetalert2"
import { api } from "../../api/api"
import { MenuContext } from "../../context/MenuContext"
import { CategoryAdditional } from "../CategoryAdditional"
import { mascaraMoeda, maskCurrency } from "../../utils/utils"
import { Toast } from "../../utils/Toast"
import { DEFAULT_IMAGE } from "../../utils/constantes";
import { ModalImageCrop, ModalImageLogo } from "../Modals/ImageCrop";
import ProductService from '../../services/Product'
import './style.css'
import { ProductContext } from "../../context/ProductContext";

export function ProductsOfCategory({ item }) {
  const { setProductId, load, setIsDragging, isDragging, data, setData, copyAndPaste, setCopyAndPaste } = useContext(MenuContext)
  const { setProductEdit } = useContext(ProductContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [timer, setTimer] = useState(null)
  const [productsOfCategory, setProductsOfCategory] = useState({
    pricing: 0
  })
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [crop, setCrop] = useState(false)

  useEffect(() => {
    setProductsOfCategory({
      pricing: item.pricing
    })
  }, [item])

  function updateAssortmentAdditionalProduct(items) {
    const calculate = items.map((e, index) => {
      return {
        id: e.id,
        name: e.name,
        assortment: index + 1
      }
    })

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      await api.put('/category/additional/additionals/assortment', calculate)
        .then(() => {
          Toast.success("Informação atualizada.")
        })
    }, 1000)

    setTimer(newTimer)
  }

  async function updatedProduct(id, key, valueInput) {
    if (!id || !key) {
      Swal.fire(
        'Registro salvo.',
        '',
        'warning'
      )
    }

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      await api.put('/products/' + id, {
        [key]: valueInput
      }).then(() => {
        Toast.success("Informação atualizada.")
      })
    }, 1000)

    setTimer(newTimer)
  }

  async function destroy(id) {
    const confirmation = await Toast.confirm()
    if (confirmation) {
      await api.delete('/products/' + id)
      await load() // TODO - Colocara para retirar do array que já foi buscado, melhor que recarregar tudo
    }
  }

  function isDraggable() {
    const aux = !modalOpen
    setModalOpen(oldState => aux)

    if (aux) {
      setIsDragging({ name: 'categoryAdditional', draggable: true })
    } else {
      setIsDragging({ name: 'product', draggable: true })
    }
  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...item.categoryAdditional];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    const aux = data
    const _data = data.filter((value) => {
      const result = value.productsOfCategory.filter((x) => {
        if (x.categoryAdditional.length > 0)
          return true
        else
          return false
      })
      return result.length > 0
    })

    _data.filter(y => {
      const dataValue = y.productsOfCategory.find(xx => {
        return xx.id === dragItemContent.productId
      })
      if (dataValue) {
        return true
      }
      return false
    })[0].productsOfCategory.filter((v) => v.id === copyListItems[0].productId)[0].categoryAdditional = copyListItems

    const indexRegister = data.findIndex((value) => value.id === _data[0].id)
    aux.splice(indexRegister, 1);
    aux.splice(indexRegister, 0, _data[0]);

    const newData = aux.map((value, index) => {
      return {
        ...value,
        assortment: index + 1,
        productsOfCategory: value.productsOfCategory.map((product, indexProduct) => {
          return {
            ...product,
            assortment: indexProduct + 1,
            categoryAdditional: product.categoryAdditional.map((_value, _index) => {
              return {
                ..._value,
                assortment: _index + 1,
              }
            })
          }
        })
      }
    })
    updateAssortmentAdditionalProduct(copyListItems)
    setData(newData)
  };

  function copyCategoryAdditionalsAndAdditionals(id) {
    api.get(`/category/additional/copy/${copyAndPaste}/${id}`)
      .then(() => {
        Toast.success("Informações copiada.")
        load()
      }).finally(() => {
        setCopyAndPaste('')
      })
  }

  useEffect(() => {
    if (crop) {
      new window.bootstrap.Modal(document.getElementById('modalCropImageLogo'), {}).show()
    }
  }, [crop])

  function toggleModal() {
    setCrop(!crop)
    new window.bootstrap.Modal(document.getElementById('modalCropImageLogo'), {}).hide()
  }

  function upload(id) {
    return async (blob) => {
      await ProductService.upload(blob, id)
      await load()
    }
  }

  function editProduct(body) {
    setProductEdit(body)
  }


  const idProduct = item.id.replace(/-/ig, '').replace(/[0-9]/g, '')
  const activeProduct = Boolean(Number(item.active))
  const styleAccordion = {
    borderLeft: `5px solid ${activeProduct ? '#13293D' : '#dcdcdc  '}`,
    borderRadius: 5,
    marginBottom: 5
  }

  return (
    <>
      {crop && <ModalImageLogo
        toggleModal={toggleModal}
        width={Infinity} height={Infinity}
        uploadImageBanner={upload(item.id)}
      />}
      <div className="accordion-item" style={styleAccordion}>
        <div className="accordion-header" id="headingOne" >
          <button style={{ display: 'flex', alignItems: 'center', gap: 20, fontSize: '1rem' }} className="accordion-button" type="button" aria-expanded="true" aria-controls={idProduct}>
            <div style={{ display: 'flex', gap: 10 }}>
              <FaAlignJustify color="#666" size="1rem" onClick={() => isDraggable()} data-bs-toggle="collapse" data-bs-target={`#${idProduct}`} />
            </div>
            <div className="icons-crop" onClick={() => setCrop(true)}>
              <div className="icon-edit-crop">
                <FaEdit color="#666" size="1rem" />
              </div>
              <div className="image-crop" style={{ display: 'flex', alignItems: 'center' }}>
                <img loading="lazy" style={{ width: 50, height: 50 }} src={item?.image || DEFAULT_IMAGE} alt="" />
              </div>
            </div>
            {item.name}
          </button>
          <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <input className="form-control form-control-sm input-focus"
              style={{ width: 100, marginRight: 10, textAlign: 'center' }} type="text" aria-label=".form-control-sm example"
              value={maskCurrency(productsOfCategory.pricing)}
              onChange={(input) => {
                setProductsOfCategory({ pricing: mascaraMoeda(input.target.value) })
                updatedProduct(item.id, 'pricing', mascaraMoeda(input.target.value))
              }}
            />
            <div className="form-check form-check-sm form-switch" style={{ fontSize: '1rem' }}>
              <input className="form-check-input" type="checkbox" defaultChecked={activeProduct} onChange={(input) => updatedProduct(item.id, 'active', input.target.checked)} role="switch" id="flexSwitchCheckDefault" />
            </div>

            <div className="dropdown">
              <button className="btn btn-sm b-border" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true">
                <FaAngleDown />
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li className="dropdown-item" onClick={() => setProductId(item.id)} data-bs-toggle="modal" data-bs-target="#modalCategoryAdditional">Adicionar</li>
                <li className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalProduct" onClick={() => editProduct(item)}>Editar</li>
                <li className="dropdown-item" onClick={() => destroy(item.id)}>Deletar</li>
                {copyAndPaste &&
                  <li className="dropdown-item" onClick={() => copyCategoryAdditionalsAndAdditionals(item.id)}>Colar</li>
                }
              </ul>
            </div>
          </div>
        </div>
        <div id={idProduct} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionProducts">
          <div className="accordion-body">
            {item.categoryAdditional.map((value, index) =>
            (
              <div
                onDragStart={(e) => (isDragging.name === 'categoryAdditional') ? dragStart(e, index) : null}
                onDragEnter={(e) => (isDragging.name === 'categoryAdditional') ? dragEnter(e, index) : null}
                onDragEnd={() => (isDragging.name === 'categoryAdditional') ? drop() : null}
                draggable={(isDragging.name === 'categoryAdditional') ? true : false}
                key={index}
              >
                <CategoryAdditional key={value.id} item={value} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>

  )
}