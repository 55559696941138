import { useContext, useEffect, useState } from "react"
import _ from "lodash"

import { api } from "../../api/api"
import { NavBar } from "../../components/NavBar"
import { FaAngleDown, FaProductHunt } from "react-icons/fa"
import { ProductModal } from "../../components/Modals/Product"
import { Toast } from "../../utils/Toast"
import { ProductContext } from "../../context/ProductContext"
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai"
import { MenuContext } from "../../context/MenuContext"
import { GlobalContext } from "../../context/GlobalContext"
import { maskCurrency } from "../../utils/utils"
import { DateTime } from "luxon"

export function ProductPage() {
  const { isAccess } = useContext(GlobalContext)
  const { findAllCategory } = useContext(MenuContext)
  const { setProductEdit, productEdit } = useContext(ProductContext)
  const [products, setProducts] = useState([])

  const [search, setSearch] = useState('')

  const [orderBy, setOrderby] = useState({
    field: 'name',
    order: 'asc'
  })

  useEffect(() => {
    findAll()
    findAllCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function findAll() {
    const response = await api.get('/products')
    const { data: { data } } = response
    setProducts(data)
  }

  async function destroy(id) {
    const confirmation = await Toast.confirm()
    if (confirmation) {
      await api.delete('/products/' + id)
      setProducts(products.filter(e => e.id !== id))
      Toast.warning("Produto deletado com sucesso")
    }
  }

  async function onChange(id, key, value) {
    if (!isAccess('produtos', 'update')) {
      Toast.warning('Você não tem permissão para atualizar')
      return
    }


    const oldState = [...products]
    setProducts(products.map(e => {
      if (e.id === id) {
        return {
          ...e,
          [key]: value
        }
      }
      return e
    }))

    await api.put(`/products/${id}`, {
      [key]: value
    }).catch(() => {
      setProducts(oldState)
    })

    Toast.success('Registro atualizado')
  }

  useEffect(() => {
    if (productEdit.id) {
      const myModal = new window.bootstrap.Modal(document.getElementById('modalProduct'), {})
      myModal.toggle()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productEdit.id])

  return (
    <>
      <ProductModal isLocal={true} isMenu={false} loadProducts={() => findAll()} />
      <NavBar>
        <div className="container-fluid">
          <div className="mt-3 p-2 bg-dark-blue-1 rounded fw-bold d-flex justify-content-between  align-items-center border mb-3">
            <p className="text-white" style={{ fontSize: '1.3rem' }}>  <FaProductHunt className="mb-1" color='white' size={22} /> Produtos</p>
            {isAccess('produtos', 'create') && (
              <button className="btn btn-sm bg-light btn-circle" data-bs-toggle="modal" data-bs-target="#modalProduct">
                <i className="fas fa-plus"></i>
              </button>
            )}
          </div>
          <input type="text" className="form-control form-control-sm" placeholder="Digite o nome do produto" onChange={(e) => setSearch(e.target.value)} value={search} />
          <br />
          <div style={{ height: 600, overflow: 'auto' }}>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th onClick={() => orderBy.order === 'asc' ? setOrderby({ field: 'name', order: 'desc' }) : setOrderby({ field: 'name', order: 'asc' })}>
                    Nome {orderBy.order === 'asc' ? <AiOutlineArrowDown className="pointer" /> : <AiOutlineArrowUp className="pointer" />}
                  </th>
                  <th>Criado em:</th>
                  <th>Tipo</th>
                  <th onClick={() => orderBy.order === 'asc' ? setOrderby({ field: 'active', order: 'desc' }) : setOrderby({ field: 'active', order: 'asc' })}>
                    Ativo {orderBy.order === 'asc' ? <AiOutlineArrowDown className="pointer" /> : <AiOutlineArrowUp className="pointer" />}
                  </th>
                  <th>Preço</th>
                  <th >Ações</th>
                </tr>
              </thead>
              <tbody>
                {_.orderBy(products, (a) => a[orderBy.field], orderBy.order).filter(e => {
                  if (search) {
                    return String(e.name).toLowerCase().includes(search.toLowerCase())
                  } else {
                    return e
                  }
                }).map((product) => {
                  return (
                    <tr key={product.id}>
                      <td>{product.name}</td>
                      <td>{DateTime.fromISO(product.createdAt).toFormat('dd/MM/yyyy HH:mm')}</td>
                      <td>
                        {Boolean(product.isMenu) === true && (<span className="badge bg-primary">Delivery</span>)}
                        {Boolean(product.isLocal) === true && (<span className="ms-2 badge bg-success">Venda Local</span>)}
                      </td>
                      <td>
                        <input className="pointer form-check-input" onChange={(e) => onChange(product.id, 'active', e.target.checked)} type="checkbox" checked={Boolean(product.active)} id="flexCheckDefault" />
                      </td>
                      <td>
                        {maskCurrency(product.pricing)}
                      </td>
                      <td>
                        {(isAccess('produtos', 'update') || isAccess('produtos', 'delete')) && (
                          <div className="dropdown">
                            <button className="btn btn-sm border shadow-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true">
                              <FaAngleDown style={{ fontSize: '1rem' }} />
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              {isAccess('produtos', 'update') && (
                                <li className="dropdown-item" onClick={() => setProductEdit(product)}>Editar</li>
                              )}

                              {isAccess('produtos', 'delete') && (
                                <li className="dropdown-item" onClick={() => destroy(product.id)}>Deletar</li>
                              )}
                            </ul>
                          </div>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

        </div>
      </NavBar>
    </>
  )
}


