import { useEffect, useState } from "react"
import { api } from "../../../../../api/api"
import { Toast } from "../../../../../utils/Toast"
import { useSelector } from "react-redux"

export function DefineStyle() {
  const configurations = useSelector(state => state.configuration.data)

  const [cores, setCores] = useState({
    primary: '#212529',
    button: '#212529',
    alert: '#212529'
  })

  useEffect(() => {
    setCores({
      alert: configurations?.cor?.alert,
      button: configurations?.cor?.button,
      primary: configurations?.cor?.primary,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations.id])

  async function save() {
    await api.post('/configurations', { cor: cores })
    Toast.success()
  }

  return (
    <>
      <hr />
      <h5>Configurações de cores</h5>

      <div className='d-flex justify-content-start gap-5 mt-3'>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', border: '1px solid #dcdcdc', padding: 10, borderRadius: 5, textAlign: 'center', width: '100%', maxWidth: 150 }}>
          <label htmlFor="">Cor primária</label>
          <br />
          <input value={cores.primary} onChange={(e) => setCores({ ...cores, primary: e.target.value })} type='color' className='form-control form-control-sm' />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', border: '1px solid #dcdcdc', padding: 10, borderRadius: 5, textAlign: 'center', width: '100%', maxWidth: 150 }}>
          <label htmlFor="">Cor Botões</label>
          <br />
          <input value={cores.button} onChange={(e) => setCores({ ...cores, button: e.target.value })} type='color' className='form-control form-control-sm' />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', border: '1px solid #dcdcdc', padding: 10, borderRadius: 5, textAlign: 'center', width: '100%', maxWidth: 150 }}>
          <label htmlFor="">Cor Alertas</label>
          <br />
          <input value={cores.alert} onChange={(e) => setCores({ ...cores, alert: e.target.value })} type='color' className='form-control form-control-sm' />
        </div>
        <div>
          <button className="btn btn-dark-blue mt-3" onClick={save}>Salvar cores</button>
        </div>
      </div>
    </>
  )
}