import { MdOutlineDateRange } from "react-icons/md"
import './style.css'
import { useContext } from "react"
import { GlobalContext } from "../../../context/GlobalContext"

export function MercadoPago () {
  const { user } = useContext(GlobalContext)

  return (
    <div className="modal fade" id="mercadoPagoPlanos" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div style={{ position: 'relative', width: '100%' }}>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ position: 'absolute', right: 10, top: 10, zIndex: 9999 }}></button>
          </div>
          <br />
          <div className="modal-body overflow-auto" style={{ maxHeight: 768 }}>
            <div className="mb-3 text-center row" style={{ alignItems: 'flex-end' }} >
              <div className="col-sm-12 rounded " style={{}}>
                <img width={220} className="" src="https://pedidos-images.s3.amazonaws.com/BMS+PEDIDO+-+LAYOUT+SISTEMA.png" alt="" />
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="card shadow-sm" style={{ width: "60%", minWidth: 350 }}>
                  <div className="card-body">
                    {user.plan === 'free' && (
                      <>
                        <h4>Plataforma</h4>
                        <div>
                          <p style={{ color: '#555', fontSize: 28, fontWeight: 'bold', paddingTop: 10, paddingBottom: 10, lineHeight: 1.2 }}>R$ 129,90</p>
                        </div>
                        <button onClick={() => window.open('https://api.whatsapp.com/send?phone=553138919414', '_blank')} className="btn-plan">Quero assinar</button>
                      </>
                    )}

                    {user.plan === 'basic' && (
                      <>
                        <h4>Plano Básico</h4>
                        <div>
                          <p style={{ color: '#555', fontSize: 28, fontWeight: 'bold', paddingTop: 10, paddingBottom: 10, lineHeight: 1.2 }}>R$ 129,90</p>
                        </div>
                        <button onClick={() => window.open('https://api.whatsapp.com/send?phone=553138919414', '_blank')} className="btn-plan">Upgrade plano completo R$ 229,90</button>
                      </>
                    )}

                    {user.plan === 'complet' && (
                      <>
                        <h4>Plano completo</h4>
                        <button onClick={() => window.open('https://api.whatsapp.com/send?phone=553138919414', '_blank')} className="btn-plan">Falar sobre o plano?</button>
                      </>
                    )}

                  </div>
                </div>
              </div>
              <div>
                <br />
                <a href="https://api.whatsapp.com/send?phone=553138919414" target="_blank" className="mt-3" rel="noreferrer">Problemas?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}