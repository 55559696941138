/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaBars, FaCashRegister, FaProductHunt } from 'react-icons/fa'
import { CiMoneyBill, CiViewList } from 'react-icons/ci'
import { BiGroup } from 'react-icons/bi'
import { IoExitOutline, IoTicketOutline } from 'react-icons/io5'
import { RiCustomerServiceLine, RiUserStarLine } from 'react-icons/ri'
import { AiOutlineSetting } from 'react-icons/ai'
import { GrLink, GrUser } from 'react-icons/gr'

import { HiLockClosed, HiOutlineHome, HiOutlineDocumentReport } from 'react-icons/hi'
import { MdOutlineSportsMotorsports, MdOutlineDeliveryDining } from 'react-icons/md'

import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useEffect, useState } from 'react'
import { useDistrict } from '../../hooks/useDistrict'
import { useCompany } from '../../hooks/useCompany'
import { isMobile } from 'react-device-detect';
import { api } from '../../api/api'
import { Toast } from '../../utils/Toast'
import { MercadoPago } from '../Modals/MercadoPago'
import { BsArrowsFullscreen, BsBag, BsFillInboxesFill } from 'react-icons/bs'
import { loadGMaps } from '../../utils/loadMaps'
import { useIntegrationWhatsApp } from '../../hooks/useIntegrationWhatsApp'
import { Wpp } from '../Modals/Wpp'
import { CLEAR_SELECT_ORDER } from '../../stores/reducers/OrderReducers'
import { LuStickyNote } from 'react-icons/lu'
import { GlobalContext } from '../../context/GlobalContext'


export function NavBar ({ children }) {
  const { isAccess, user } = useContext(GlobalContext)
  const natigate = useNavigate()
  const { updatedComapny } = useCompany()
  const { checkWpp } = useIntegrationWhatsApp()

  const company = useSelector(state => state.company.data)
  const dispatch = useDispatch()

  const { districts, loadDeliveryDistrict } = useDistrict()
  const { load, sectors, loadSectors, loadConfiguration, configurations } = useCompany()

  const [wppStatus, setWppStatus] = useState('Conectando...')
  const [companyStatus, setCompanyStatus] = useState(null)

  function logout () {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    natigate('/')
  }

  useEffect(() => {
    if (!company.id)
      load()

    if (!configurations.id) {
      loadConfiguration()
    }

    if (districts.length === 0)
      loadDeliveryDistrict()

    if (sectors.length === 0) {
      loadSectors()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (configurations.id) {
      loadGMaps(() => {

      }, configurations.googleKey)
    }
  }, [configurations.googleKey, configurations.id])


  async function paused (paused = null, minute) {
    if (paused === 'open') {
      setCompanyStatus('Loja aberta')
    } else {
      setCompanyStatus('Loja fechada')
    }

    await api.post('/companies/paused', {
      minutes: minute,
      paused: paused
    })

    updatedComapny({
      paused: paused === 'open' ? false : true
    })

    if (paused !== null) {
      return Toast.success('Registro salvo com sucesso.')
    }

    return Toast.success(`Loja ficará pausada durante ${minute}.`)
  }

  useEffect(() => {
    setTimeout(async () => {
      const result = await checkWpp()
      if (result) {
        setWppStatus('Conectado')
      } else {
        setWppStatus('Desconectado')
      }
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    (async () => {
      const { status } = await api.get('companies/company/schedules/close/or/open')
      if (status === 204) {
        setCompanyStatus('Loja aberta')
      } else {
        setCompanyStatus('Loja fechada')
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  function clear () {
    isMobile && document.body.classList.toggle('sb-sidenav-toggled')
    dispatch(CLEAR_SELECT_ORDER())
  }

  useEffect(() => {
    if (!configurations.idStore) {
      return
    }

    const script = document.createElement('script');
    script.src = "https://widgets.ifood.com.br/widget.js";
    script.async = true;

    script.onload = () => {
      window.iFoodWidget.init({
        widgetId: '59ce0211-7575-4665-b91e-0ee1d71c6181',

        merchantIds: [
          configurations.idStore
        ],
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [configurations.idStore]);

  return (
    <>

      {(isAccess() !== 'garcom') && (
        <>
          <MercadoPago />
          <Wpp status={wppStatus} />
          <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark-blue-1" >
            <Link className="navbar-brand ps-3" to="/home">
              <img width={150} height={'100%'} loading='lazy' src="https://pedidos-images.s3.amazonaws.com/default/logo-bms-pedidos.png" alt="" />
            </Link>
            <button className="btn btn-link btn-sm order-1 order-lg-0  ms-auto ms-md-0 me-3 me-lg-4" onClick={() => document.body.classList.toggle('sb-sidenav-toggled')} href="#!">
              <FaBars color='#fff' />
            </button>


            {window.screen.width > 765 && (
              <>

                <button type='button' className='btn btn-sm btn text-white border' data-bs-toggle="modal" data-bs-target="#modalWpp"> <img src="https://cardapio.bmspedidos.com.br/wpp-logo.png" width={30} alt="" /> {wppStatus}</button>
                <div className='ms-2 text-white text-center' style={{ display: localStorage.getItem('active') === 'true' ? 'none' : 'block' }}>
                  O seu plano estará ativo por mais 7 dias. Para evitar bloqueio, faça a ativação do plano novamente
                </div>

                {companyStatus !== null && <p className={`fs-4 text-white fw-bold ms-3 ${companyStatus === 'Loja fechada' ? 'bg-danger' : 'bg-success'} p-1 rounded`} style={{ cursor: 'pointer', fontWeight: 'bold' }}>{companyStatus}</p>}

                {companyStatus === null && (
                  <div className="spinner-border ms-4 text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}

                <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                  <button type='button' className='btn btn-sm btn text-white border' onClick={() => {
                    if (company.domain) {
                      window.open(`https://${company.domain}`, '_blank')
                    } else {
                      window.open(`${process.env.REACT_APP_CARDAPIO}/${company.link}`, '_blank')
                    }
                  }}> <CiViewList color='white' size={18} /> Acessar Cardápio</button>
                  <div className="dropdown d-md-inline-block ms-2 dropstart">
                    <button className="btn btn-sm btn text-white border dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <HiLockClosed color='white' size={18} />  Fechar / Pausar loja
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item pointer" onClick={() => paused('close', 1)}>Fechar até o próximo dia</a></li>
                      <li><a className="dropdown-item pointer" onClick={() => paused('open', 1)}>Abrir loja</a></li>
                      <div className="divider"></div>
                      <li><a className="dropdown-item pointer" onClick={() => paused(null, 15)}>15 minutos</a></li>
                      <li><a className="dropdown-item pointer" onClick={() => paused(null, 30)}>30 minutos</a></li>
                      <li><a className="dropdown-item pointer" onClick={() => paused(null, 45)}>45 minutos</a></li>
                      <li><a className="dropdown-item pointer" onClick={() => paused(null, 60)}>1 horas</a></li>
                      <li><a className="dropdown-item pointer" onClick={() => paused(null, 120)}>2 horas</a></li>
                      <li><a className="dropdown-item pointer" onClick={() => paused(null, 180)}>3 horas</a></li>
                      <li><a className="dropdown-item pointer" onClick={() => paused(null, 360)}>6 horas</a></li>
                      <li><a className="dropdown-item pointer" onClick={() => paused(null, 720)}>12 horas</a></li>
                    </ul>
                  </div>
                </form>
              </>
            )}
          </nav>
          <div id="layoutSidenav" style={{}}>
            <div id="layoutSidenav_nav">
              <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 70px)' }}>
                <div className="sb-sidenav-menu">
                  <div className="nav">
                    <Link className="nav-link" to="/home" onClick={() => clear()}>
                      <div className="sb-nav-link-icon">
                        <HiOutlineHome color='white' size={18} />
                      </div>
                      Inicio
                    </Link>

                    {isAccess('cardapio', 'view') && (
                      <Link className="nav-link" to="/menu" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <CiViewList color='white' size={18} />
                        </div>
                        Cardápio
                      </Link>
                    )}
                    {isAccess('caixa', 'view') && (
                      <Link className="nav-link" to="/safe" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <FaCashRegister color='white' size={18} />
                        </div>
                        Caixa
                      </Link>
                    )}

                    {isAccess('produtos', 'view') && (
                      <Link className="nav-link" to="/product" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <FaProductHunt color='white' size={18} />
                        </div>
                        Produtos
                      </Link>
                    )}

                    {isAccess('estoque', 'view') && (
                      <Link className="nav-link" to="/stock" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <BsFillInboxesFill color='white' size={18} />
                        </div>
                        Estoque
                      </Link>
                    )}

                    {isAccess('sector', 'view') && (
                      <Link className="nav-link" to="/sectors" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <BsArrowsFullscreen color='white' size={18} />
                        </div>
                        Setores
                      </Link>
                    )}

                    {isAccess('fiscal', 'view') && (
                      <>
                        {user.plan === 'complet' && (
                          <Link className="nav-link" to="/fiscal" onClick={() => clear()}>
                            <div className="sb-nav-link-icon">
                              <LuStickyNote color='white' size={18} />
                            </div>
                            Fiscal
                          </Link>
                        )}
                      </>

                    )}

                    {isAccess('clientes', 'view') && (
                      <Link className="nav-link" to="/customer" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <BiGroup color='white' size={18} />
                        </div>
                        Clientes
                      </Link>
                    )}

                    {isAccess('entregadores', 'view') && (
                      <Link className="nav-link" to="/motoboy" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <MdOutlineSportsMotorsports color='white' size={18} />
                        </div>
                        Entregadores
                      </Link>
                    )}

                    {isAccess('cupons', 'view') && (
                      <Link className="nav-link" to="/cupom" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <IoTicketOutline color='white' size={18} />
                        </div>
                        Cupons de desconto
                      </Link>
                    )}

                    {isAccess('metodo_entrega', 'view') && (
                      <Link className="nav-link" to="/method-delivery" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <MdOutlineDeliveryDining color='white' size={18} />
                        </div>
                        Métodos de Entrega
                      </Link>
                    )}

                    {isAccess('avaliacao', 'view') && (
                      <Link className="nav-link" to="/rating" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <RiUserStarLine color='white' size={18} />
                        </div>
                        Avaliações
                      </Link>
                    )}

                    {isAccess('relatorio', 'view') && (
                      <Link className="nav-link" to="/report" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <HiOutlineDocumentReport color='white' size={18} />
                        </div>
                        Relatórios
                      </Link>
                    )}

                    {isAccess('configuracoes', 'view') && (
                      <Link className="nav-link" to="/company" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <AiOutlineSetting color='white' size={18} />
                        </div>
                        Meu dados
                      </Link>
                    )}

                    {isAccess('forma_pagamento', 'view') && (
                      <Link className="nav-link" to="/payment-method" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <CiMoneyBill color='white' size={18} />
                        </div>
                        Formas de pagamento
                      </Link>
                    )}

                    {isAccess('integracao', 'view') && (
                      <>
                        {user.plan === 'complet' && (

                          <Link className="nav-link" to="/integration" onClick={() => clear()}>
                            <div className="sb-nav-link-icon">
                              <GrLink className='bg-white rounded-pill p-1' color='white' size={18} />
                            </div>
                            Integrações
                          </Link>
                        )}
                      </>
                    )}
                    {isAccess('usuario', 'view') && (
                      <Link className="nav-link" to="/users" onClick={() => clear()}>
                        <div className="sb-nav-link-icon">
                          <GrUser className='bg-white rounded-pill p-1' color='white' size={18} />
                        </div>
                        Usuários
                      </Link>

                    )}
                    {window.screen.width < 765 && (
                      <>
                        <Link className="nav-link" onClick={() => {
                          if (company.domain) {
                            window.open(`https://${company.domain}`, '_blank')
                          } else {
                            window.open(`${process.env.REACT_APP_CARDAPIO}/${company.link}`, '_blank')
                          }
                        }}>
                          <div className="sb-nav-link-icon">
                            <CiViewList color='white' size={18} />
                          </div>
                          Acessar Cardápio
                        </Link>

                        <Link className="nav-link" onClick={() => clear()}>
                          <div className="sb-nav-link-icon">
                            <HiLockClosed color='white' size={18} />
                          </div>
                          <div className="dropdown d-md-inline-block" >
                            <button className="btn btn-sm text-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              Fechar / Pausar loja
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                              <li><a className="dropdown-item pointer" onClick={() => paused(false, null)}>Abrir loja</a></li>
                              <li><a className="dropdown-item pointer" onClick={() => paused(true, null)}>Fechar loja</a></li>
                              <div className="divider"></div>
                              <li><a className="dropdown-item pointer" onClick={() => paused(null, 15)}>15 minutos</a></li>
                              <li><a className="dropdown-item pointer" onClick={() => paused(null, 30)}>30 minutos</a></li>
                              <li><a className="dropdown-item pointer" onClick={() => paused(null, 45)}>45 minutos</a></li>
                              <li><a className="dropdown-item pointer" onClick={() => paused(null, 60)}>1 hora</a></li>
                              <li><a className="dropdown-item pointer" onClick={() => paused(null, 120)}>2 hora</a></li>
                              <li><a className="dropdown-item pointer" onClick={() => paused(null, 180)}>3 hora</a></li>
                            </ul>
                          </div>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
                <div className="sb-sidenav-footer">
                  <div style={{ textAlign: 'left!important', fontSize: '1rem' }} className='text-start'>
                    <hr className='mb-0 mt-0' />
                    <button className='btn btn-sm text-white  p-2 mb-2 mt-2 w-100 text-start' data-bs-toggle="modal" data-bs-target="#mercadoPagoPlanos">
                      <BsBag size={18} className='mb-1' /> Meu plano
                    </button>
                    <button onClick={() => window.open('https://api.whatsapp.com/send?phone=553138919414', '_blank')} className='btn btn-sm text-white p-2 mb-2 mt-2 w-100 text-start'>
                      <RiCustomerServiceLine size={18} />  Suporte
                    </button>
                  </div>
                  <hr className='mb-0 mt-0' />
                  <div className='text-start' >
                    <button className='border rounded btn btn-sm mt-2 text-white w-100 text-start ' style={{ fontSize: '1rem' }} onClick={() => logout()}> <IoExitOutline /> Sair</button>
                  </div>
                </div>
              </nav>
            </div>
            <div id="layoutSidenav_content" className="elementoteste" style={{ height: 'calc(100vh -100px)', overflow: 'auto' }}>
              {children}
            </div>
          </div>
        </>
      )}

      {isAccess() === 'garcom' && (
        <div style={{ width: '100%' }}>
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">BMS Pedidos</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                </ul>
                <div className="d-flex">
                  <button className="btn border text-white btn-sm" onClick={() => logout()}>Deslogar</button>
                </div>

              </div>
            </div>
          </nav>
          {children}
        </div>
      )}
    </>
  )
}