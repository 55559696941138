import { useContext, useEffect, useState } from "react"
import Swal from "sweetalert2"
import { api } from "../../api/api"
import { FaAngleDown } from "react-icons/fa"
import { Toast } from "../../utils/Toast"
import { MenuContext } from "../../context/MenuContext"
import { mascaraMoeda, maskCurrency } from "../../utils/utils"

export function Additional({ item }) {
  const { load, setAdditional } = useContext(MenuContext)
  const [timer, setTimer] = useState(null)

  const [dataAditional, setDataAditional] = useState({
    pricing: 0
  })

  useEffect(() => {
    setDataAditional({
      pricing: item.pricing
    })
  }, [item])

  async function updated(id, key, valueInput, reload = false) {
    if (!id || !key) {
      Swal.fire(
        'Registro salvo.',
        '',
        'warning'
      )
    }

    clearTimeout(timer)

    const newTimer = setTimeout(async () => {
      await api.put('/additional/' + id, {
        [key]: valueInput
      }).then(async () => {
        Toast.success('Registro atualizado com sucesso.')
        if (reload) {
          await load()
        }
      }).catch((res) => Toast.warning())
    }, 1000)

    setTimer(newTimer)
  }

  async function destroy(id) {
    const confirmation = await Toast.confirm()
    if (confirmation) {
      await api.delete('/additional/' + id)
      await load() // TODO - Colocara para retirar do array que já foi buscado, melhor que recarregar tudo
    }
  }

  async function activeOrDisalbed(id, checked, name) {
    const disabledOrActive = checked ? 'Ativar' : 'Desativar'
    const disabledAll = await Toast.confirm(`Gostaria de ${disabledOrActive} o adicional ${name} de todos os produtos?`, 'Não', 'Sim')
    if (disabledAll) {
      await api.put('/additional/disabled-or-active/all/' + id, { active: checked })
      await load()
    } else {
      await updated(item.id, 'active', checked, true)
    }

    Toast.success('Registro atualizado com sucesso.')
  }

  return (
    <>
      <li key={item.id} className="list-group-item d-flex " style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <p style={{ margin: 0, padding: 0, fontSize: '1rem' }}>{item.name}</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input className="form-control form-control-sm input-focus"
            style={{ width: 100, marginRight: 10, textAlign: 'center' }}
            type="text" aria-label=".form-control-sm example"
            onChange={(e) => {
              setDataAditional({ pricing: mascaraMoeda(e.target.value) })
              updated(item.id, 'pricing', mascaraMoeda(e.target.value))
            }}
            value={maskCurrency(dataAditional.pricing)}
          />
          <div className="form-check form-check-sm form-switch" style={{ fontSize: '1rem' }}>
            <input className="form-check-input" type="checkbox"
              defaultChecked={Boolean(item.active)}
              onChange={(input) => activeOrDisalbed(item.id, input.target.checked, item.name)}
              role="switch" id="flexSwitchCheckDefault" />
          </div>

          <div className="dropdown">
            <button className="btn btn-sm b-border" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="true">
              <FaAngleDown />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li className="dropdown-item" onClick={() => {
                setAdditional({
                  id: item.id,
                  name: item.name,
                  categoryAdditionalId: item.categoryAdditionalId,
                  pricing: item.pricing,
                  active: item.active,
                })
              }} data-bs-toggle="modal" data-bs-target="#modalAdditional">Editar</li>
              <li className="dropdown-item" onClick={() => destroy(item.id)}>Deletar</li>
            </ul>
          </div>
        </div>
      </li>
    </>
  )
}