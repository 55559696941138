import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  data: [],
  table: null
}

export const TableReducers = createSlice({
  name: 'sectors',
  initialState: INITIAL_STATE,
  reducers: {
    SET_TABLE: (state, action) => {
      state.table = action.payload
    },
    SET_TABLES_DATA: (state, action) => {
      state.data = action.payload
    },
    ADD_NEW_TABLE: (state, action) => {
      state.data.push(action.payload)
    },
    ADD_NEW_COMMAND_TABLE: (state, action) => {
      state.data = state.data.map((e) => {
        if (e.id === action.payload.idTable) {
          e.commands = [
            ...(e.commands),
            action.payload
          ]
          e.available = false
        }

        return e
      })
    },
    DELETE_COMMAND_TABLE: (state, action) => {
      state.data = state.data.map((e) => {
        const aux = e.commands.find(e => e.id === action.payload)
        if (aux) {
          e.commands = e.commands.filter(e => e.id !== action.payload)
        }

        return e
      })
    },
    CLOSE_TABLE: (state, action) => {
      state.data = state.data.map(e => {
        if (e.id === action.payload) {
          return {
            ...e,
            available: true
          }
        }

        return e
      })

      state.table = {
        ...state.table,
        available: true
      }
    },
    CLEAR_SELECT_TABLE: (state, action) => {
      state.table = null
    },
    SELECT_TABLE: (state, action) => {
      state.table = action.payload
    },
    UPDATE_TABLE: (state, action) => {
      state.data = state.data.map(e => {
        if (e.id === action.payload.idTable) {
          return {
            ...e,
            ...action.payload
          }
        }

        return e
      })
    },
    UPDATE_COMMAND: (state, action) => {
      state.data = state.data.map((e) => {
        const aux = e.commands.find(e => e.id === action.payload.id)

        if (aux) {
          const commands = e.commands.filter((x) => x.id !== action.payload.id)
          e.commands = [
            ...commands,
            {
              ...aux,
              ...action.payload
            }
          ]
        }

        return e
      })
    }
  }
})

export const { SET_TABLE, SET_TABLES_DATA, ADD_NEW_COMMAND_TABLE, ADD_NEW_TABLE, DELETE_COMMAND_TABLE, UPDATE_COMMAND, CLOSE_TABLE, CLEAR_SELECT_TABLE, SELECT_TABLE, UPDATE_TABLE } = TableReducers.actions

export default TableReducers.reducer